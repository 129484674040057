import { observer } from 'mobx-react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { CircularProgress, Grid } from '@mui/material';
import userStore from '../store/user.store';
import { Roles } from '../types/user.types';
import Error404 from '../components/Error/error-404';
import { BDBContainer } from './BDB-Container/bdb-container';
import { colorThemes } from '../variables/style.variables';

interface ProtectedRouteProps {
  allowedRoles: Roles[];
}

const ProtectedRoute = ({ allowedRoles }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isLoadingSession, isLoggedIn } = userStore;
  const hasRole = userStore.hasUserAtLeastOneRole(allowedRoles);

  if (isLoadingSession) {
    return (
      <BDBContainer maxWidth="xl">
        <Grid container justifyContent="center" alignItems="center" mt="calc(50vh - 50px)">
          <CircularProgress
            size={50}
            sx={{
              color: colorThemes.primary,
            }}
          />
        </Grid>
      </BDBContainer>
    );
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return hasRole ? <Outlet /> : <Error404 />;
};

export default observer(ProtectedRoute);
