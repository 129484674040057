export enum FileTypesId {
  CV = '16bf0f3d-fc4d-4fa7-bb5e-c74342b5d2f4',
  Equipment = '19011d62-0779-4ae3-be43-3588a98b93e1',
  PPK = '3bfc3096-013d-46a5-926f-ff1f10c05ea6',
  Hobby = '8e53c826-0126-4242-a5cd-02eb6e7d55f0',
  CertificateOfEmployment = '97f47aec-c8c4-47f4-b3be-4170476ec753',
  PIT2 = 'a655d90b-01d9-4eb2-b0c2-16541d4381a8',
  PersonalQuestionnaire = 'f63ce961-91b1-45a4-ad89-d1c4e0d5f84a',
}

export interface ResponsePostFileProps {
  id: string;
  fileTypeId: string;
  fileType: string;
  fileName: string;
  extension: string;
  contentType: string;
  createdAt: string;
}

export interface GetFileProps {
  id: string;
  name: string;
  directory: string;
}
