import {
  Select,
  MenuItem,
  IconButton,
  Input,
  SelectChangeEvent,
  Box,
  Badge,
  Grid,
  Divider,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { useState, ChangeEvent, MouseEvent } from 'react';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import _ from 'lodash';
import { BDBButton } from '../BDB-Button/bdb-button';
import { BDBTextField } from '../BDB-TextField/bdb-text-field';
import { colorThemes } from '../../variables/style.variables';

export interface SelectFilterOnCloseArgs {
  isChanged: boolean;
}

export interface SelectFilterOption {
  value: string;
  label: string;
}

export interface SelectFilterProps {
  options: SelectFilterOption[];
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
  onClose?: (args: SelectFilterOnCloseArgs) => void;
}

export const SelectFilter = ({
  options,
  onClose,
  onChange,
  value,
  disabled = false,
}: SelectFilterProps) => {
  const [search, setSearch] = useState('');
  const [prevValue, setPrevValue] = useState<string[]>([]);
  const [isSelectFilterOpen, setIsSelectFilterOpen] = useState(false);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClose = () => {
    setSearch('');
    setPrevValue(value);
    setIsSelectFilterOpen(false);

    const isChanged = !_.isEqual(value, prevValue);

    if (typeof onClose === 'function') onClose({ isChanged });
  };

  const handleOpen = () => {
    setIsSelectFilterOpen(true);
  };

  const handleChange = (e: SelectChangeEvent<string[]>) => {
    onChange(e.target.value as string[]);
  };

  const selectAll = () => {
    onChange(options.map((option) => option.value));
  };

  const resetSelect = () => {
    onChange([]);
  };

  const invisibleInput = (
    <Input
      sx={{
        opacity: 0,
        position: 'absolute',
        left: 1,
        bottom: 0,
        pointerEvents: 'none',
        width: 1,
      }}
    />
  );

  const stopImmediatePropagation = (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  const renderedOptions = options.map((option) => {
    const isSearchInLabel = option.label.toLowerCase().includes(search.toLocaleLowerCase());

    if (!isSearchInLabel) return null;

    return (
      <MenuItem key={option.value} value={option.value}>
        <Checkbox
          checked={value.indexOf(option.value) > -1}
          style={{
            color: colorThemes.primary,
            padding: 4,
            marginRight: 8,
          }}
        />
        <ListItemText primary={option.label} />
      </MenuItem>
    );
  });

  return (
    <Box sx={{ position: 'relative' }}>
      <IconButton onClick={() => setIsSelectFilterOpen(true)} disabled={disabled}>
        <Badge
          badgeContent={value?.length}
          sx={{
            '.MuiBadge-badge': {
              backgroundColor: colorThemes.primary,
              color: colorThemes.white,
            },
          }}
        >
          <FilterListRoundedIcon />
        </Badge>
      </IconButton>
      <Select
        open={isSelectFilterOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value}
        onChange={handleChange}
        input={invisibleInput}
        displayEmpty
        multiple
        inputProps={{
          MenuProps: {
            sx: {
              maxHeight: '400px',
              '& .MuiList-root': {
                paddingY: 0,
                minWidth: '250px',
              },
              '&& .Mui-selected': {
                backgroundColor: `${colorThemes.black_08} !important`,
              },
            },
          },
        }}
      >
        <Grid
          position="sticky"
          top={0}
          sx={{
            backgroundColor: colorThemes.white,
          }}
          zIndex={2}
        >
          <MenuItem
            dense
            onKeyDown={(e) => e.stopPropagation()}
            onClickCapture={(e) => stopImmediatePropagation(e)}
            disableTouchRipple
            disableRipple
            sx={{
              '&:hover, &:focus, &:active, &.Mui-focusVisible': {
                background: '#fff !important',
                cursor: 'auto',
              },
              paddingTop: '16px',
              paddingBottom: '8px',
            }}
          >
            <BDBTextField
              value={search}
              onChange={handleSearchChange}
              placeholder="Search"
              size="small"
              InputProps={{ style: { fontSize: 14 } }}
              fullWidth
            />
          </MenuItem>
          <Divider />
        </Grid>
        {renderedOptions}
        <Grid
          position="sticky"
          mt={1}
          bottom={0}
          width="100%"
          sx={{
            backgroundColor: colorThemes.white,
          }}
          zIndex={2}
        >
          <Divider />
          <Grid container direction="row" justifyContent="flex-end" padding={1}>
            <Grid item>
              <BDBButton variant="text" onClick={selectAll} fontSize="14px" size="small">
                All
              </BDBButton>
            </Grid>
            <Grid item>
              <BDBButton variant="text" onClick={resetSelect} fontSize="14px" size="small">
                Reset
              </BDBButton>
            </Grid>
            <Grid item>
              <BDBButton variant="text" onClick={handleClose} fontSize="14px" size="small">
                Save
              </BDBButton>
            </Grid>
          </Grid>
        </Grid>
      </Select>
    </Box>
  );
};
