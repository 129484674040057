import { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../../assets/styles/App.css';
import Routing from '../../layouts/routing';
import Header from '../Header/header';
import { SessionProvider } from '../SessionProvider/session-provider';
import { ModalProvider } from '../ModalProvider/modal-provider';

const App: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <SessionProvider>
        <ModalProvider>
          <Header />
          <Routing />
        </ModalProvider>
      </SessionProvider>
    </BrowserRouter>
  );
};

export default App;
