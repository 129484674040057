import { Table, TableContainer, Paper } from '@mui/material';
import { IEmployee } from '../../../types/employee.types';
import { EmployeesTableBody } from './employees-table-body';
import { EmployeesTableHead } from './employees-table-head';
import { EmployeesTableSkeleton } from './employees-table-skeleton';

interface EmployeeTableProps {
  employees: IEmployee[];
  isLoadingEmployees: boolean;
}

export const EmployeesTable = ({ employees, isLoadingEmployees }: EmployeeTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <EmployeesTableHead />
        {isLoadingEmployees ? (
          <EmployeesTableSkeleton />
        ) : (
          <EmployeesTableBody employees={employees} />
        )}
      </Table>
    </TableContainer>
  );
};
