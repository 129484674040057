import { Grid, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { colorThemes } from '../../variables/style.variables';

interface SubInfoProps {
  contents: string[];
}

export const SubInfo = ({ contents }: SubInfoProps) => {
  return (
    <Grid container direction="column" rowSpacing={1}>
      {contents.map((content) => (
        <Grid
          key={content}
          item
          container
          direction="row"
          alignItems="start"
          justifyContent="center"
          wrap="nowrap"
          sx={{ color: colorThemes.grey, fontSize: '14px' }}
        >
          <InfoOutlinedIcon />
          <Typography fontWeight={400} ml={1} mt="1px">
            {content}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
