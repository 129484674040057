import { request } from '../services/api.services';
import { ISkillGroup } from '../types/skill.types';

export const getSkills = async (): Promise<ISkillGroup[]> => {
  try {
    const response = await request({
      url: '/skill-groups',
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};
