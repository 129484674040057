import { useState, MouseEvent } from 'react';
import { Grid, Typography, IconButton, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { colorThemes } from '../../../../../variables/style.variables';
import BoardColumnMenu from './board-column-menu';

interface BoardColumnHeaderProps {
  name: string;
  boardColumnId: string;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  isDragging: boolean;
  isBoardColumnTasksScrolled: boolean;
}

export const BoardColumnHeader = ({
  name,
  dragHandleProps,
  isBoardColumnTasksScrolled,
  boardColumnId,
}: BoardColumnHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Grid
      item
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={2}
      pt={1}
      pb={0.5}
      sx={{
        boxShadow: isBoardColumnTasksScrolled ? `0 3px 3px -2px ${colorThemes.grey50}` : '',
        transition: 'box-shadow .15s linear',
      }}
    >
      <Tooltip title="Drag to change columns order" placement="top" arrow>
        <Grid item {...dragHandleProps}>
          <Typography fontSize={14} fontWeight={500} color={colorThemes.black}>
            {name}
          </Typography>
        </Grid>
      </Tooltip>
      <Grid item container width="auto">
        <Grid item>
          <Tooltip title="Create task" placement="top" arrow>
            <IconButton size="small">
              <AddRoundedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <IconButton size="small" onClick={handleOpenMenu}>
            <MoreHorizRoundedIcon />
          </IconButton>
          <BoardColumnMenu {...{ anchorEl, setAnchorEl, boardColumnId, name }} />
        </Grid>
        <Grid item>
          <Tooltip title="Drag to change columns order" placement="top" arrow>
            <IconButton size="small" disableRipple {...dragHandleProps}>
              <DragIndicatorOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};
