import { FunctionComponent, useEffect } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, SelectChangeEvent, Button, Autocomplete, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import Select from '../../components/FormFields/select';
import { IPersonDetailed } from '../../types/candidate.types';
import { ISelectedSkills, ISkillGroup } from '../../types/skill.types';

interface ISkillsProps {
  control: Control;
  errors: FieldErrorsImpl;
  selectedSkills: ISelectedSkills[];
  skills: ISkillGroup[];
  setSelectedSkills: (selectedSkills: ISelectedSkills[]) => void;
  candidate: IPersonDetailed | null;
  id: string | undefined;
}

const Skills: FunctionComponent<ISkillsProps> = ({
  control,
  errors,
  selectedSkills,
  skills,
  setSelectedSkills,
  candidate,
  id,
}) => {
  useEffect(() => {
    if (id) {
      const result = candidate?.skillGroups.map((group) => {
        const skillGroupIndex = skills.findIndex((skill) => group.id === skill.id);
        return {
          skillGroupIndex,
          skillId: group.skills.map((skill) => ({
            label: skill.name,
            value: skill.id,
          })),
        };
      });

      setSelectedSkills(result || []);
    }
  }, []);

  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Skills</Typography>
      </Grid2>
      {selectedSkills.map((selectedSkill, selectedSkillIndex) => {
        return (
          <Grid2
            xs={12}
            container
            component="div"
            key={`selectedSkillKey${selectedSkillIndex.toString()}`}
          >
            <Grid2 xs={6}>
              <Select
                id={String(selectedSkill.skillGroupIndex)}
                registerOptions={{ required: true }}
                control={control}
                label="Skill group"
                multiple={false}
                error={!!errors[selectedSkill.skillGroupIndex]}
                options={skills.map((skill, index) => {
                  return { label: skill.name, value: index };
                })}
                labelId={`skillGroupLabelId${selectedSkillIndex}`}
                onSelect={(e: SelectChangeEvent<string | string[] | Date | number>) => {
                  const oldData = [...selectedSkills];
                  oldData[selectedSkillIndex].skillGroupIndex = +e.target.value;
                  oldData[selectedSkillIndex].skillId = [];
                  setSelectedSkills(oldData);
                }}
                defaultValue={selectedSkill.skillGroupIndex}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Controller
                name={skills[selectedSkill.skillGroupIndex]?.id || 'tempId'}
                control={control}
                rules={{ required: true }}
                defaultValue={selectedSkill.skillId}
                render={({ field: { onChange, onBlur } }) => (
                  <Autocomplete
                    freeSolo
                    multiple
                    onBlur={onBlur}
                    value={selectedSkill.skillId}
                    onChange={(e, value) => {
                      onChange(e);
                      const oldData = [...selectedSkills];
                      oldData[selectedSkillIndex].skillId = value as string[];
                      setSelectedSkills(oldData);
                    }}
                    id={skills[selectedSkill.skillGroupIndex]?.id || 'tempId'}
                    options={
                      skills[selectedSkill.skillGroupIndex]?.skills?.map((skill) => {
                        return { label: skill.name, value: skill.id };
                      }) || []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Skills"
                        error={!!errors[skills[selectedSkill.skillGroupIndex]?.id]}
                      />
                    )}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...selectedSkills];
            oldData.push({ skillGroupIndex: 0, skillId: [] });
            setSelectedSkills(oldData);
          }}
        >
          Add Skill Group
        </Button>
      </Grid2>
    </>
  );
};

export default Skills;
