import { TableHead, TableRow, TableCell } from '@mui/material';

interface AdminUsersTableHeadCell {
  id: string;
  label: string;
  minWidth: string;
  width?: string;
}

const adminUsersTableHeadCells: readonly AdminUsersTableHeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: '200px',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: '350px',
  },
  {
    id: 'phoneNumber',
    label: 'Phone number',
    minWidth: '130px',
  },
  {
    id: 'actions',
    label: '',
    minWidth: '190px',
    width: '190px',
  },
];

export function AdminUsersTableHead() {
  return (
    <TableHead>
      <TableRow>
        {adminUsersTableHeadCells.map((adminUsersTableHeadCell) => (
          <TableCell
            key={adminUsersTableHeadCell.id}
            sx={{
              minWidth: adminUsersTableHeadCell.minWidth,
              width: adminUsersTableHeadCell.width || 'auto',
            }}
          >
            {adminUsersTableHeadCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
