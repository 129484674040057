import { axiosAuthorized } from './api';
import { SignInRequest, SignInResponse } from '../types/api-identity.types';
import { authRequest } from '../services/api.services';
import { IUser } from '../types/user.types';

export const signIn = async ({ email, password }: SignInRequest): Promise<string | false> => {
  try {
    const signInResponse = await axiosAuthorized.post<SignInResponse>('/identity/auth/sign-in', {
      email,
      password,
    });
    return signInResponse.data.jwtToken;
  } catch {
    return false;
  }
};

export const getUser = async (id: string): Promise<IUser | false> => {
  try {
    const response = await authRequest<IUser>({
      url: `/users/${id}`,
      method: 'get',
    });

    return response.data;
  } catch {
    return false;
  }
};
