import { makeAutoObservable } from 'mobx';

class UIStore {
  isHiddenAppNav = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsHiddenAppNav = (isHiddenAppNav: boolean) => {
    this.isHiddenAppNav = isHiddenAppNav;
  };
}

export const uistore = new UIStore();
