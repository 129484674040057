import { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { Roles } from '../../types/user.types';
import { roleStore } from '../../store/role.store';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';
import { getRecruiterList } from '../../api/candidate.api';
import Input from '../../components/FormFields/input';
import { IFormValues, ISelectOption } from '../../types/form.types';
import { ProjectProps, PostProjectProps, PatchProjectProps } from '../../types/project.types';
import Select from '../../components/FormFields/select';
import { addProject, editProject } from '../../api/project.api';

const AddEditProject: FC<{
  onClose: () => void;
  onSuccess: () => Promise<void>;
  openDialog: boolean;
  defaultValueProject: ProjectProps;
  setDefaultValueProject: (values: ProjectProps) => void;
}> = ({ defaultValueProject, onClose, setDefaultValueProject, openDialog, onSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [recruiters, setRecrutiers] = useState<ISelectOption[]>([]);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const isProjectExist = !!defaultValueProject.id;

  const fetchRecruiters = async () => {
    const users = await getRecruiterList(Roles.HR);
    if (users) {
      setRecrutiers(
        users.map((user) => {
          return { label: `${user.firstName} ${user.lastName}`, value: user.userId };
        }),
      );
    }
  };

  const addProjectOnSubmit = async (values: IFormValues) => {
    const { name, ownerId } = values;
    const payload: PostProjectProps = {
      name,
      ownerId,
    };
    setIsProcessing(true);
    const response = await addProject(payload);

    onSuccess();
    onClose();
    setIsProcessing(false);

    if (!response) {
      modalStore.push({
        title: 'Something went wrong',
        content: 'There was some error while creating new project',
        type: ModalTypes.Info,
      });
      return;
    }

    modalStore.push({
      title: 'Project created',
      content: `Project ${name} was created successfully.`,
      type: ModalTypes.Info,
    });
  };

  const editProjectOnSubmit = async (values: IFormValues) => {
    const { name, ownerId } = values;
    const payload: PatchProjectProps = {
      isNameUpdated: true,
      name,
      ownerId,
      isOwnerUpdated: true,
    };
    setIsProcessing(true);
    const response = await editProject(defaultValueProject.id, payload);

    onSuccess();
    onClose();
    setIsProcessing(false);

    if (!response) {
      modalStore.push({
        title: 'Something went wrong',
        content: `There was some error while updating ${name} project`,
        type: ModalTypes.Info,
      });
      return;
    }

    modalStore.push({
      title: 'Project created',
      content: `Project ${name} was updated successfully.`,
      type: ModalTypes.Info,
    });
  };

  const onSubmit = async (values: IFormValues) => {
    if (!defaultValueProject.id) {
      await addProjectOnSubmit(values);
    } else {
      await editProjectOnSubmit(values);
    }
  };

  const getDialogTitle = () => {
    return isProjectExist ? `Edit project ${defaultValueProject.name}` : 'Create new project';
  };

  useEffect(() => {
    roleStore.fetchRoles();
    fetchRecruiters();
  }, []);

  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{getDialogTitle()}</DialogTitle>
      <DialogContent dividers>
        <form id="add-edit-user-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            component="fieldset"
            disabled={isProcessing}
            border="none"
            direction="column"
            rowSpacing={3}
            paddingY={2}
          >
            <Grid item container direction="row" columnSpacing={3}>
              <Grid item xs>
                <Input
                  id="name"
                  registerOptions={{ required: true }}
                  control={control}
                  label="Project name"
                  type="text"
                  error={!!errors.firstName}
                  defaultValue={defaultValueProject.name}
                  onType={(e) => {
                    const oldData = { ...defaultValueProject };
                    oldData.name = e.target.value;
                    setDefaultValueProject(oldData);
                  }}
                />
              </Grid>
              <Grid item xs>
                <Select
                  id="ownerId"
                  registerOptions={{ required: true }}
                  control={control}
                  label="Recruiter"
                  multiple={false}
                  error={!!errors.recruiterId}
                  options={recruiters}
                  labelId="recruiterLabelId"
                  defaultValue={defaultValueProject.ownerId}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <BDBButton
              variant="text"
              type="submit"
              maxWidth="80px"
              form="add-edit-user-form"
              loading={isProcessing}
            >
              Save
            </BDBButton>
          </Grid>
          <Grid item>
            <BDBButton
              onClick={() => {
                onClose();
                reset((formValues) => ({
                  ...formValues,
                }));
              }}
              variant="text"
              maxWidth="100px"
              disabled={isProcessing}
            >
              Cancel
            </BDBButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default observer(AddEditProject);
