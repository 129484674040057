import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Container, Toolbar, Link, Box, Button, IconButton, Skeleton } from '@mui/material';
import { observer } from 'mobx-react';
import userStore from '../../store/user.store';
import LogoImage from '../../assets/img/logo.png';
import { UserMenu } from '../UserMenu/user-menu';
import { colorThemes } from '../../variables/style.variables';
import { HeaderAppNav } from './header-app-nav';
import { uistore } from '../../store/ui.store';

const Header = () => {
  const { isLoggedIn, isLoadingSession } = userStore;
  const { isHiddenAppNav } = uistore;
  const [isOpenAppNav, setIsOpenAppNav] = useState<boolean>(false);
  const isVisbileOpenAppNavButton = !isHiddenAppNav || (isHiddenAppNav && isLoggedIn);
  const isVisibleLoginButton = !isLoggedIn && !isLoadingSession && !isHiddenAppNav;

  const toggleAppNav = () => {
    setIsOpenAppNav((prevIsOpenAppNav) => !prevIsOpenAppNav);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: colorThemes.white }}>
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {isVisbileOpenAppNavButton && (
              <IconButton
                size="large"
                edge="start"
                color="default"
                sx={{ mr: 2, color: colorThemes.black }}
                onClick={toggleAppNav}
                disabled={isLoadingSession}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link component={RouterLink} to="/dashboard">
              <img
                src={LogoImage}
                alt=""
                style={{ width: '90px', marginRight: '30px', display: 'block' }}
              />
            </Link>
            <HeaderAppNav toggleAppNav={toggleAppNav} isOpenAppNav={isOpenAppNav} />
          </Box>
          {isLoadingSession && <Skeleton variant="text" width={140} height={28} />}
          {isLoggedIn && !isLoadingSession && <UserMenu />}
          {isVisibleLoginButton && (
            <Button
              variant="text"
              size="large"
              sx={{
                color: colorThemes.black,
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '500',
              }}
              component={RouterLink}
              to="/login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default observer(Header);
