import { authRequest } from '../services/api.services';
import { ApplicationPostProps, ApplicationGetResponse } from '../types/application.types';

export const postAddApplication = async (params: ApplicationPostProps): Promise<boolean> => {
  try {
    await authRequest({
      url: `/applications`,
      method: 'post',
      data: params,
    });

    return true;
  } catch {
    return false;
  }
};

export const getApplicants = async (): Promise<ApplicationGetResponse> => {
  try {
    const response = await authRequest({
      url: `/applications`,
      method: 'get',
    });

    return response?.data;
  } catch {
    return [];
  }
};
