import { authRequest } from '../services/api.services';
import { Board, BoardColumn, BoardTaskItem } from '../types/boards.types';

export interface GetBoardsResultItem extends Pick<Board, 'id' | 'name'> {}
export interface GetBoardsResult extends Array<GetBoardsResultItem> {}

interface AddBoardArgs {
  name?: string;
}

interface UpdateBoardColumnArgs {
  boardId: string;
  boardColumnId?: string;
  name?: string;
  ordinal?: number;
}

interface CreateBoardColumnArgs {
  boardId: string;
  name: string;
}

interface UpdateBoardColumnRequest {
  name?: { value: string };
  ordinal?: { value: number };
}

interface UpdateBoardTaskArgs {
  boardId: string;
  boardTaskId: string;
  name?: string;
  ordinal?: number;
}

interface UpdateBoardTaskRequest {
  name?: { value: string };
  ordinal?: { value: number };
}

interface GetBoardTasksArgs {
  boardId: string;
  boardColumnId?: string;
  OnlyTasksNotAssignedToColumn?: boolean;
}

export const getBoards = async (): Promise<GetBoardsResult> => {
  try {
    const response = await authRequest<GetBoardsResult>({
      url: '/boards',
      method: 'get',
    });

    return response?.data;
  } catch {
    return [];
  }
};

export const getBoard = async (boardId: string): Promise<Board | false> => {
  try {
    const response = await authRequest<Board>({
      url: `/boards/${boardId}`,
      method: 'get',
    });

    return response?.data;
  } catch {
    return false;
  }
};

export const getBoardTasks = async ({
  boardId,
  boardColumnId,
  OnlyTasksNotAssignedToColumn,
}: GetBoardTasksArgs): Promise<BoardTaskItem[] | false> => {
  try {
    const query = new URLSearchParams();
    if (boardColumnId) query.append('boardColumnId', boardColumnId);
    if (OnlyTasksNotAssignedToColumn) query.append('OnlyTasksNotAssignedToColumn', 'true');

    const response = await authRequest<BoardTaskItem[]>({
      url: `/boards/${boardId}/tasks?${query.toString()}`,
      method: 'get',
    });

    return response?.data;
  } catch {
    return false;
  }
};

export const updateBoardColumn = async ({
  boardId,
  boardColumnId,
  name,
  ordinal,
}: UpdateBoardColumnArgs): Promise<boolean> => {
  try {
    const requestData: UpdateBoardColumnRequest = {};

    if (name) requestData.name = { value: name };
    if (typeof ordinal === 'number') requestData.ordinal = { value: ordinal };

    await authRequest({
      url: `/boards/${boardId}/columns/${boardColumnId}`,
      method: 'patch',
      data: requestData,
    });

    return true;
  } catch {
    return false;
  }
};

export const updateBoardName = async ({
  boardId,
  name,
}: UpdateBoardColumnArgs): Promise<boolean> => {
  const requestData: UpdateBoardTaskRequest = {};

  if (name) requestData.name = { value: name };

  try {
    await authRequest({
      url: `/boards/${boardId}`,
      method: 'patch',
      data: requestData,
    });
    return true;
  } catch {
    return false;
  }
};
export const deleteColumnFormBoard = async (params: {
  boardId: string;
  boardColumnId: string;
}): Promise<boolean> => {
  try {
    await authRequest({
      url: `/boards/${params.boardId}/columns/${params.boardColumnId}`,
      method: 'delete',
    });
    return true;
  } catch {
    return false;
  }
};

export const createBoard = async ({ name }: AddBoardArgs): Promise<GetBoardsResultItem | false> => {
  try {
    const response = await authRequest({
      url: '/boards',
      method: 'post',
      data: { name },
    });
    return response?.data;
  } catch {
    return false;
  }
};

export const createBoardColumn = async ({
  boardId,
  name,
}: CreateBoardColumnArgs): Promise<BoardColumn | false> => {
  try {
    const response = await authRequest<BoardColumn>({
      url: `/boards/${boardId}/columns`,
      method: 'post',
      data: {
        name,
      },
    });

    return response?.data;
  } catch {
    return false;
  }
};

export const deleteBoard = async (boardId: string): Promise<boolean> => {
  try {
    await authRequest({
      url: `/boards/${boardId}`,
      method: 'delete',
    });
    return true;
  } catch {
    return false;
  }
};

export const updateBoardTask = async ({
  boardId,
  boardTaskId,
  name,
  ordinal,
}: UpdateBoardTaskArgs): Promise<boolean> => {
  try {
    const requestData: UpdateBoardTaskRequest = {};

    if (name) requestData.name = { value: name };
    if (typeof ordinal === 'number') requestData.ordinal = { value: ordinal };

    await authRequest({
      url: `/boards/${boardId}/tasks/${boardTaskId}`,
      method: 'patch',
      data: requestData,
    });

    return true;
  } catch {
    return false;
  }
};
