import { forwardRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { colorThemes } from '../../variables/style.variables';

type BDBTextFieldProps = TextFieldProps & {
  customColor?: string;
};

export const BDBTextField = forwardRef<HTMLInputElement | HTMLDivElement, BDBTextFieldProps>(
  ({ customColor = colorThemes.primary, ...props }: BDBTextFieldProps, ref) => {
    return (
      <TextField
        ref={ref}
        {...props}
        sx={{
          ...(props.sx || {}),
          '& label.Mui-focused': {
            color: customColor,
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: customColor,
            },
          },
        }}
      />
    );
  },
);
