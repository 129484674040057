import { Table, TableContainer, Paper } from '@mui/material';
import { CandidatesTableHead } from './candidates-table-head';
import { CandidatesTableBody } from './candidates-table-body';
import { CandidatesTableSkeleton } from './candidates-table-skeleton';
import { Person } from '../../../types/person.types';
import { CandidatesFilters } from '../candidates';

interface CandidatesTableProps {
  filters: CandidatesFilters;
  candidates: Person[];
  isLoadingCandidates: boolean;
  handleChangeFilters: (value: string[], filter: keyof CandidatesFilters) => void;
  handleCloseFilter: (isChanged: boolean, f: keyof CandidatesFilters) => unknown;
}

export const CandidatesTable = ({
  filters,
  candidates,
  isLoadingCandidates,
  handleChangeFilters,
  handleCloseFilter,
}: CandidatesTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <CandidatesTableHead
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          handleCloseFilter={handleCloseFilter}
        />
        {isLoadingCandidates ? (
          <CandidatesTableSkeleton />
        ) : (
          <CandidatesTableBody candidates={candidates} />
        )}
      </Table>
    </TableContainer>
  );
};
