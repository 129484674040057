import { useCallback, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { useDropzone, FileError, FileRejection } from 'react-dropzone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { colorThemes } from '../../variables/style.variables';
import { FileHeader } from './file-header';

interface UploadableFile {
  file: File;
  errors: FileError[];
}

export const Dropzone = () => {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    const mappedAcc = acceptedFiles.map((file) => ({ file, errors: [] }));
    setFiles((curr) => [...curr, ...mappedAcc, ...rejectedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
  });

  const onDelete = (file: File) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  };
  return (
    <Container sx={{ border: '1px dashed', borderRadius: 1, borderColor: 'grey.500' }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        rowSpacing={3}
        paddingY={2}
        {...getRootProps()}
      >
        <Grid item {...getInputProps()} />
        <Grid item>
          <FileUploadIcon sx={{ fontSize: '40px', color: `${colorThemes.primary}` }} />
        </Grid>
        <Grid item>
          <Typography fontSize="16px" fontWeight={400} color={colorThemes.grey}>
            Drag and drop some your CV here, or click to select files
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center" rowSpacing={3} paddingY={2}>
        {files.map((element) => (
          <Grid key={element?.file?.name} item>
            <FileHeader file={element.file} onDelete={onDelete} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
