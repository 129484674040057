import { Grid, Box } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { useEffect, useRef, useState } from 'react';
import { BoardColumn as IBoardColumn } from '../../../../../types/boards.types';
import { BoardColumnHeader } from './board-column-header';
import { BoardColumnTasks } from './board-column-tasks';
import { BOARD_COLUMN_WIDTH } from '../../../../../variables/board-workspace.variables';
import { colorThemes } from '../../../../../variables/style.variables';

interface BoardColumnProps {
  boardColumn: IBoardColumn;
  boardColumnTasksHeight: number;
}

export const BoardColumn = ({ boardColumn, boardColumnTasksHeight }: BoardColumnProps) => {
  const { id: boardColumnId, name: boardColumnName, ordinal: boardColumnOrdinal } = boardColumn;
  const boardColumnTasksRef = useRef<HTMLDivElement | null>(null);
  const [isBoardColumnTasksScrolled, setIsBoardColumnTasksScrolled] = useState(false);

  const handleBoardColumnTasksScroll = () => {
    const scrollTop = boardColumnTasksRef?.current?.scrollTop;

    if (typeof scrollTop === 'undefined') return;

    const isScrolled = scrollTop > 0;
    setIsBoardColumnTasksScrolled(isScrolled);
  };

  useEffect(() => {
    const currentElement = boardColumnTasksRef?.current;

    if (currentElement) {
      currentElement.addEventListener('scroll', handleBoardColumnTasksScroll);
    }
  }, [boardColumnTasksRef]);

  return (
    <Draggable draggableId={boardColumnId} index={boardColumnOrdinal}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot;
        const columnOpacity = isDragging ? 0.75 : 1;

        return (
          <Grid
            width={`${BOARD_COLUMN_WIDTH}px`}
            item
            container
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Box
              width="100%"
              bgcolor={colorThemes.grey10}
              borderRadius={2}
              style={{
                opacity: columnOpacity,
                transition: 'opacity .15s linear',
              }}
            >
              <BoardColumnHeader
                name={boardColumnName}
                boardColumnId={boardColumnId}
                dragHandleProps={provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                isBoardColumnTasksScrolled={isBoardColumnTasksScrolled}
              />
              <BoardColumnTasks
                boardColumnTasksHeight={boardColumnTasksHeight}
                ref={boardColumnTasksRef}
              />
            </Box>
          </Grid>
        );
      }}
    </Draggable>
  );
};
