import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import { colorThemes } from '../../variables/style.variables';

export interface BDBButtonProps extends ButtonProps {
  maxWidth?: string;
  customColor?: string;
  loading?: boolean;
  component?: string;
  fontWeight?: number;
  fontSize?: string;
  minWidth?: string;
  lineHeight?: string;
  textTransform?: 'none' | 'uppercase' | 'lowercase';
}

export const BDBButton = ({
  maxWidth = 'none',
  customColor = colorThemes.primary,
  loading = false,
  children,
  variant = 'contained',
  fontWeight = 500,
  fontSize = '14px',
  lineHeight = '20px',
  minWidth = 'auto',
  textTransform = 'none',
  ...props
}: BDBButtonProps) => {
  const isTextVariant = variant === 'text';
  const backgroundColor = isTextVariant ? 'transparent' : customColor;
  const textColor = isTextVariant ? customColor : colorThemes.white;

  return (
    <Box sx={{ position: 'relative', width: '100%', maxWidth, minWidth }}>
      <Button
        variant={variant}
        disabled={loading}
        {...props}
        sx={{
          ...(props.sx || {}),
          lineHeight,
          fontSize,
          fontWeight,
          backgroundColor,
          color: textColor,
          '&:hover': {
            backgroundColor,
            color: textColor,
          },
          width: '100%',
          textTransform,
          minWidth,
        }}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={22}
          sx={{
            position: 'absolute',
            color: colorThemes.primary,
            top: '50%',
            left: '50%',
            marginTop: '-10px',
            marginLeft: '-10px',
          }}
        />
      )}
    </Box>
  );
};
