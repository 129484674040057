import { FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import { ICertificate } from '../../types/candidate.types';
import Input from '../../components/FormFields/input';
import TextArea from '../../components/FormFields/textarea';

interface ICertificateProps {
  control: Control;
  errors: FieldErrorsImpl;
  certificates: ICertificate[];
  setCertificates: (certificates: ICertificate[]) => void;
}

const Certificates: FunctionComponent<ICertificateProps> = ({
  control,
  errors,
  certificates,
  setCertificates,
}) => {
  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Certificates</Typography>
      </Grid2>
      {certificates.map((certificate, index) => {
        return (
          <Grid2
            container
            xs={12}
            key={`certificateKey${index.toString()}`}
            tabIndex={Number(!!certificate)}
          >
            <Grid2 xs={12}>
              <Input
                id={`certificateName${index}`}
                type="text"
                label="Name"
                registerOptions={{ required: true }}
                error={!!errors[`certificateName${index}`]}
                control={control}
                onType={(e) => {
                  const oldData = [...certificates];
                  oldData[index].name = e.target.value;
                  setCertificates(oldData);
                }}
                defaultValue={certificate.name}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextArea
                id={`certificateDescription${index}`}
                label="Description"
                registerOptions={{ required: true }}
                error={!!errors[`certificateDescription${index}`]}
                control={control}
                onType={(e) => {
                  const oldData = [...certificates];
                  oldData[index].description = e.target.value;
                  setCertificates(oldData);
                }}
                rows={5}
                defaultValue={certificate.description}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...certificates];
            oldData.push({
              name: '',
              description: '',
            });
            setCertificates(oldData);
          }}
        >
          Add Certificate
        </Button>
      </Grid2>
    </>
  );
};

export default Certificates;
