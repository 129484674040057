import { FC } from 'react';
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormErrors } from '../../../components/FormErrors/form-errors';
import Input from '../../../components/FormFields/input';
import { BDBRadio } from '../../../layouts/BDB-Radio/bdb-radio';
import { BDBTypography } from '../../../layouts/BDB-Typography/bdb-typography';
import { BDBGrid } from '../../../layouts/BDB-Grid/bdb-grid';
import { UploadButton } from '../../../components/UploadButton/upload-button';
import { FileTypesId } from '../../../types/file.types';

export const Equipment: FC<{
  setEquipmentFile: (equipmentFile: FormData | null) => void;
}> = ({ setEquipmentFile }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    emailCreation: emailCreationError,
    calamariAccess: calamariAccessError,
    primetric: primetricError,
    officeCard: officeCardError,
    firstAid: firstAidError,
    bhp: bhpError,
    phone: phoneError,
  } = errors;
  return (
    <BDBGrid>
      <Grid item xs={12}>
        <BDBTypography variant="h5">Equipment</BDBTypography>
      </Grid>
      <Grid item xs={6}>
        <Input id="equipment" control={control} label="Equipment" type="text" />
      </Grid>
      <Grid item xs={6} container justifyContent="center">
        <UploadButton
          name="Equipment document"
          onChange={async (e) => {
            const target = e.target as HTMLInputElement;
            const fileArray = target.files;
            if (fileArray) {
              const formData = new FormData();
              formData.append('Files', fileArray[0]);
              formData.append('FileTypeId', FileTypesId.CV);
              setEquipmentFile(formData);
            }
          }}
          onDelete={() => {
            setEquipmentFile(null);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          id="phone"
          registerOptions={{ required: true }}
          control={control}
          label="Phone number"
          type="text"
          error={!!phoneError}
        />
        {phoneError?.message && <FormErrors messages={[String(phoneError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            E-mail creation
          </FormLabel>
          <Controller
            name="emailCreation"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {emailCreationError?.message && (
            <FormErrors messages={[String(emailCreationError?.message)]} />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Calamari access
          </FormLabel>
          <Controller
            name="calamariAccess"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {calamariAccessError?.message && (
            <FormErrors messages={[String(calamariAccessError?.message)]} />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Primetric
          </FormLabel>
          <Controller
            name="primetric"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {primetricError?.message && <FormErrors messages={[String(primetricError?.message)]} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Office card
          </FormLabel>
          <Controller
            name="officeCard"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {officeCardError?.message && <FormErrors messages={[String(officeCardError?.message)]} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            First aid
          </FormLabel>
          <Controller
            name="firstAid"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {firstAidError?.message && <FormErrors messages={[String(firstAidError?.message)]} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            BHP
          </FormLabel>
          <Controller
            name="bhp"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="true" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="false" control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {bhpError?.message && <FormErrors messages={[String(bhpError?.message)]} />}
        </FormControl>
      </Grid>
    </BDBGrid>
  );
};
