import * as yup from 'yup';

export const schema = yup.object().shape({
  startDate: yup.string().nullable(true).required('Date required'),
  onboarding: yup.string().nullable(true).required('Date required'),
  welcomePack: yup.string().required('Choose option'),
  lastContact: yup.string().nullable(true).required('Date required'),
  multiSport: yup.string().required('Multisport required'),
  luxMed: yup.string().required('Luxmed required'),
  emailCreation: yup.boolean().required('Choose option'),
  calamariAccess: yup.boolean().required('Choose option'),
  primetric: yup.boolean().required('Choose option'),
  officeCard: yup.boolean().required('Choose option'),
  firstAid: yup.boolean().required('Choose option'),
  bhp: yup.boolean().required('Choose option'),
  phone: yup.string().required('Phone number required'),
  expiryDate: yup.string().nullable(true).required('Date required'),
  type: yup.string().required('Choose option'),
  stage: yup.string().required('Choose option'),
  contractExtended: yup.boolean().required('Choose option'),
  nda: yup.string().required('Choose option'),
  medicalChecksExpiryDate: yup.string().required('Date required'),
  medicalChecks: yup.boolean().required('Choose option'),
});
