import * as yup from 'yup';
import { phoneRegExp } from '../../variables/regexp.variables';

export const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Email is incorrect').required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, {
      message: 'Phone number is incorrect',
      excludeEmptyString: true,
    })
    .required('Phone number is required'),
});
