import { FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import { IEducations } from '../../types/candidate.types';
import Input from '../../components/FormFields/input';
import { DatePicker } from '../../components/FormFields/date-picker';

interface IEducationsPorps {
  control: Control;
  errors: FieldErrorsImpl;
  educations: IEducations[];
  setEducations: (educations: IEducations[]) => void;
  generateDateString: (date: Date) => string;
}

const Educations: FunctionComponent<IEducationsPorps> = ({
  control,
  errors,
  educations,
  setEducations,
  generateDateString,
}) => {
  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Educations</Typography>
      </Grid2>
      {educations.map((education, index) => {
        return (
          <Grid2 xs={12} container key={`educationKey${index.toString()}`}>
            <Grid2 xs={6}>
              <Input
                id={`${education.institution}inst${index}`}
                control={control}
                error={!!errors[`${education.institution}inst${index}`]}
                registerOptions={{ required: true }}
                type="text"
                label="Institution"
                onType={(e) => {
                  const oldData = [...educations];
                  oldData[index].institution = e.target.value;
                  setEducations(oldData);
                }}
                defaultValue={education.institution}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Input
                id={`${education.scientificTitle}degree${index}`}
                control={control}
                error={!!errors[`${education.scientificTitle}degree${index}`]}
                registerOptions={{ required: true }}
                type="text"
                label="Scientific Title"
                onType={(e) => {
                  const oldData = [...educations];
                  oldData[index].scientificTitle = e.target.value;
                  setEducations(oldData);
                }}
                defaultValue={education.scientificTitle}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                id={`${education.startDate}startDate${index}`}
                label="Start Date"
                control={control}
                views={['year']}
                error={!!errors[`${education.startDate}startDate${index}`]}
                defaultValue={generateDateString(new Date(education.startDate))}
                onChange={(date) => {
                  const oldData = [...educations];
                  oldData[index].startDate = new Date(date.toString());
                  setEducations(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                id={`${education.endDate}endDate${index}`}
                control={control}
                views={['year']}
                error={!!errors[`${education.endDate}endDate${index}`]}
                label="End Date"
                onChange={(date) => {
                  const oldData = [...educations];
                  oldData[index].endDate = new Date(date.toString());
                  setEducations(oldData);
                }}
                defaultValue={generateDateString(new Date(education.endDate))}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Input
                id={`${education.location}location${index}`}
                control={control}
                error={!!errors[`${education.location}location${index}`]}
                type="text"
                label="Location"
                onType={(e) => {
                  const oldData = [...educations];
                  oldData[index].location = e.target.value;
                  setEducations(oldData);
                }}
                defaultValue={education.location}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...educations];
            oldData.push({
              institution: '',
              scientificTitle: '',
              startDate: new Date(),
              endDate: new Date(),
              location: '',
            });
            setEducations(oldData);
          }}
        >
          Add Education
        </Button>
      </Grid2>
    </>
  );
};

export default Educations;
