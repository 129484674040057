import { FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import TextArea from '../../components/FormFields/textarea';
import { UploadButton } from '../../components/UploadButton/upload-button';
import { ICvFile } from '../../types/candidate.types';
import { fileToBase64 } from '../../services/file.service';

interface IRemarksAndSubmitProps {
  control: Control;
  errors: FieldErrorsImpl;
  remarks: string;
  buttonText: 'Create' | 'Save';
  setCvFile: (cvFile: ICvFile | null) => void;
}

const RemarksAndSubmit: FunctionComponent<IRemarksAndSubmitProps> = ({
  control,
  errors,
  remarks,
  buttonText,
  setCvFile,
}) => {
  return (
    <>
      <Grid2 xs={12}>
        <TextArea
          id="remarks"
          registerOptions={{ required: true }}
          control={control}
          label="Comment"
          error={!!errors.remarks}
          rows={5}
          defaultValue={remarks}
        />
      </Grid2>
      <Grid2 xs={12}>
        <UploadButton
          name="Upload CV"
          onChange={async (e) => {
            const target = e.target as HTMLInputElement;
            const fileArray = target.files;
            if (fileArray) {
              const base64 = (await fileToBase64(fileArray[0])) as string;
              const cvFile: ICvFile = {
                contentAsBase64: base64.split('base64,')[1],
                fileNameWithExtension: fileArray[0].name,
                contentType: fileArray[0].type,
              };
              setCvFile(cvFile);
            }
          }}
          onDelete={() => {
            setCvFile(null);
          }}
        />
      </Grid2>
      <Grid2 xs={12}>
        <Button
          type="submit"
          sx={{ marginLeft: 'auto', marginRight: 1.5, display: 'block' }}
          variant="contained"
        >
          {buttonText}
        </Button>
      </Grid2>
    </>
  );
};

export default RemarksAndSubmit;
