import { useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormErrors } from '../../../../../components/FormErrors/form-errors';
import Input from '../../../../../components/FormFields/input';
import { BDBButton } from '../../../../../layouts/BDB-Button/bdb-button';
import { boardStore } from '../../../../../store/board.store';
import { modalStore } from '../../../../../store/modal.store';
import { ModalTypes } from '../../../../../variables/modal.variables';
import { IFormValues } from '../../../../../types/form.types';
import { updateBoardName } from '../../../../../api/boards.api';

export const GeneralSetting = observer(() => {
  const { boardId } = useParams();
  const { board } = boardStore;
  const [boardSetting, setBoardSetting] = useState<{ name: string | undefined }>({
    name: board?.name,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (values: IFormValues) => {
    const { name } = values;
    setIsProcessing(true);
    if (boardId) {
      const response = await updateBoardName({ boardId, name });
      setIsProcessing(false);
      if (response) {
        modalStore.push({
          title: `Board ${name} change`,
          content: `Board was updated successfully.`,
          type: ModalTypes.Info,
        });
      } else {
        modalStore.push({
          title: 'Something went wrong',
          content: 'There was error while updating board.',
          type: ModalTypes.Info,
        });
      }
    }
  };

  const { name: nameError } = errors;
  return (
    <Grid
      component="form"
      id="add-edit-user-form"
      container
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography>General Setting</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography variant="caption">Board&#39;s name</Typography>
        </Grid>
        <Grid item xs={12}>
          <Input
            id="name"
            control={control}
            type="text"
            error={!!nameError}
            defaultValue={boardSetting.name}
            onType={(e) => {
              const oldData = { ...boardSetting };
              oldData.name = e.target.value;
              setBoardSetting(oldData);
            }}
          />
          {nameError?.message && <FormErrors messages={[String(nameError?.message)]} />}
        </Grid>
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Grid item>
          <BDBButton variant="text" type="submit" form="add-edit-user-form" loading={isProcessing}>
            Save
          </BDBButton>
        </Grid>
      </Grid>
    </Grid>
  );
});
