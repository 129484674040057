import dayjs from 'dayjs';

const now = dayjs().format();
export const defaultValues = {
  medicalChecksExpiryDate: now,
  medicalChecks: false,
  equipment: '',
  emailCreation: false,
  calamariAccess: false,
  primetric: false,
  officeCard: false,
  firstAid: false,
  bhp: false,
  expiryDate: now,
  type: '',
  stage: '',
  contractExtended: false,
  nda: '',
  multiSport: '',
  luxMed: '',
  startDate: now,
  welcomePack: '',
  lastContact: now,
  onboarding: now,
};
