import { request } from '../services/api.services';
import { ILanguage } from '../types/language.types';

export const getLanguages = async (): Promise<ILanguage[]> => {
  try {
    const response = await request({
      url: '/languages',
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};

export const getLanguageLevels = async (): Promise<ILanguage[]> => {
  try {
    const response = await request({
      url: '/language-levels',
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};
