import { Grid, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { BDBRadio } from '../../../layouts/BDB-Radio/bdb-radio';
import { BDBTypography } from '../../../layouts/BDB-Typography/bdb-typography';
import { FormErrors } from '../../../components/FormErrors/form-errors';
import { BDBGrid } from '../../../layouts/BDB-Grid/bdb-grid';
import { DatePicker } from '../../../components/FormFields/date-picker';

export const Onboarding = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    startDate: startDateError,
    welcomePack: welcomePackError,
    lastContact: lastContactError,
    onboarding: onboardingError,
  } = errors;

  return (
    <BDBGrid>
      <Grid item xs={12}>
        <BDBTypography variant="h5">Onboarding</BDBTypography>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="startDate"
          label="Start date"
          control={control}
          error={!!startDateError}
          registerOptions={{ required: true }}
        />
        {startDateError?.message && <FormErrors messages={[String(startDateError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="lastContact"
          label="Last contact"
          control={control}
          error={!!lastContactError}
          registerOptions={{ required: true }}
        />
        {lastContactError?.message && <FormErrors messages={[String(lastContactError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="onboarding"
          label="Onboarding"
          control={control}
          error={!!onboardingError}
          registerOptions={{ required: true }}
        />
        {onboardingError?.message && <FormErrors messages={[String(onboardingError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Welcome pack
          </FormLabel>
          <Controller
            name="welcomePack"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="Yes" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="No" control={<BDBRadio />} label="No" />
                <FormControlLabel value="Order" control={<BDBRadio />} label="Order" />
              </RadioGroup>
            )}
          />
          {welcomePackError?.message && (
            <FormErrors messages={[String(welcomePackError?.message)]} />
          )}
        </FormControl>
      </Grid>
    </BDBGrid>
  );
};
