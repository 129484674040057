import { Grid, Card, CardActionArea } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { colorThemes } from '../../../../../variables/style.variables';
import { BOARD_COLUMN_CREATE_WIDTH } from '../../../../../variables/board-workspace.variables';

interface BoardColumnCreateProps {
  onClick: () => void;
}

export const BoardColumnCreate = ({ onClick }: BoardColumnCreateProps) => {
  return (
    <Grid width={BOARD_COLUMN_CREATE_WIDTH} item container>
      <Card
        sx={{ width: '100%', height: '100%', backgroundColor: colorThemes.grey10, boxShadow: 0 }}
      >
        <CardActionArea
          onClick={onClick}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AddRoundedIcon fontSize="medium" sx={{ color: colorThemes.grey50 }} />
        </CardActionArea>
      </Card>
    </Grid>
  );
};
