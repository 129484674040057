import { TableBody, TableCell, TableRow, Skeleton } from '@mui/material';
import { EMPLOYEES_PAGE_SIZE } from '../../../variables/config';

export const EmployeesTableSkeleton = () => {
  const rows = Array(EMPLOYEES_PAGE_SIZE).fill(0);

  const employeesTableSkeletonRows = rows.map(() => (
    <TableRow key={Math.random()}>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
    </TableRow>
  ));

  return <TableBody>{employeesTableSkeletonRows}</TableBody>;
};
