import { FunctionComponent, ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { IFormControl } from '../../types/form.types';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';

interface IInputProps extends IFormControl {
  type: 'number' | 'text' | 'password' | 'date';
  onType?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Input: FunctionComponent<IInputProps> = ({
  label = '',
  defaultValue,
  type,
  id,
  control,
  registerOptions,
  error,
  onType,
}) => {
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      render={({ field: { onChange, onBlur } }) => (
        <BDBTextField
          id={id}
          label={label}
          type={type}
          fullWidth
          onChange={(e) => {
            onChange(e);
            if (onType) onType(e);
          }}
          onBlur={onBlur}
          error={error}
          value={defaultValue}
        />
      )}
    />
  );
};

export default Input;
