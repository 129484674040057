import { FC, useState } from 'react';
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormErrors } from '../../../components/FormErrors/form-errors';
import { BDBRadio } from '../../../layouts/BDB-Radio/bdb-radio';
import { BDBTypography } from '../../../layouts/BDB-Typography/bdb-typography';
import { BDBGrid } from '../../../layouts/BDB-Grid/bdb-grid';
import { UploadButton } from '../../../components/UploadButton/upload-button';
import { DatePicker } from '../../../components/FormFields/date-picker';
import { FileTypesId } from '../../../types/file.types';

export const AdditionalInformation: FC<{
  setCertificateOfEmploymentFile: (certificateOfEmploymentFile: FormData | null) => void;
  setPpkFile: (ppkFile: FormData | null) => void;
  setPit2File: (pit2File: FormData | null) => void;
}> = ({ setCertificateOfEmploymentFile, setPpkFile, setPit2File }) => {
  const [medicalDate, setMedicalDate] = useState(true);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    medicalChecksExpiryDate: medicalChecksExpiryDateError,
    medicalChecks: medicalChecksError,
  } = errors;

  return (
    <BDBGrid>
      <Grid item xs={12}>
        <BDBTypography variant="h5">Additional informations</BDBTypography>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          disabled={medicalDate}
          id="medicalChecksExpiryDate"
          label="Medical expiry date"
          control={control}
          error={!!medicalChecksExpiryDateError}
        />
        {medicalChecksExpiryDateError?.message && (
          <FormErrors messages={[String(medicalChecksExpiryDateError?.message)]} />
        )}
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Medical checks
          </FormLabel>
          <Controller
            name="medicalChecks"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel
                  value
                  onClick={() => setMedicalDate(false)}
                  control={<BDBRadio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  onClick={() => setMedicalDate(true)}
                  control={<BDBRadio />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
          {medicalChecksError?.message && (
            <FormErrors messages={[String(medicalChecksError?.message)]} />
          )}
        </FormControl>
      </Grid>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        direction="column"
        sx={{ marginLeft: '10px' }}
      >
        <UploadButton
          name="Employment certificate"
          onChange={async (e) => {
            const target = e.target as HTMLInputElement;
            const fileArray = target.files;
            if (fileArray) {
              const formData = new FormData();
              formData.append('Files', fileArray[0]);
              formData.append('FileTypeId', FileTypesId.CertificateOfEmployment);
              setCertificateOfEmploymentFile(formData);
            }
          }}
          onDelete={() => {
            setCertificateOfEmploymentFile(null);
          }}
        />
        <UploadButton
          name="PPK"
          onChange={async (e) => {
            const target = e.target as HTMLInputElement;
            const fileArray = target.files;
            if (fileArray) {
              const formData = new FormData();
              formData.append('Files', fileArray[0]);
              formData.append('FileTypeId', FileTypesId.PPK);
              setPpkFile(formData);
            }
          }}
          onDelete={() => {
            setPpkFile(null);
          }}
        />
        <UploadButton
          name="PIT 2"
          onChange={async (e) => {
            const target = e.target as HTMLInputElement;
            const fileArray = target.files;
            if (fileArray) {
              const formData = new FormData();
              formData.append('Files', fileArray[0]);
              formData.append('FileTypeId', FileTypesId.PIT2);
              setPit2File(formData);
            }
          }}
          onDelete={() => {
            setPit2File(null);
          }}
        />
      </Grid>
    </BDBGrid>
  );
};
