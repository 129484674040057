import { FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import { IWorkExperiences } from '../../types/candidate.types';
import { ISkillGroup } from '../../types/skill.types';
import Input from '../../components/FormFields/input';
import Select from '../../components/FormFields/select';
import { ISelectOption } from '../../types/form.types';
import TextArea from '../../components/FormFields/textarea';
import { DatePicker } from '../../components/FormFields/date-picker';

interface IWorkExperiencesProps {
  control: Control;
  errors: FieldErrorsImpl;
  workExperiences: IWorkExperiences[];
  setWorkExperiences: (experiences: IWorkExperiences[]) => void;
  skills: ISkillGroup[];
  generateDateString: (date: Date) => string;
}

const WorkExperiences: FunctionComponent<IWorkExperiencesProps> = ({
  control,
  errors,
  workExperiences,
  setWorkExperiences,
  skills,
  generateDateString,
}) => {
  const generateSkillsOptions = (): ISelectOption[] => {
    let result: ISelectOption[] = [];
    const blackList: string[] = [];
    for (let i = 0; i < skills.length; i += 1) {
      if (!blackList.includes(skills[i].name))
        result = [
          ...result,
          ...skills[i].skills.map((skill) => {
            return { label: skill.name, value: skill.name };
          }),
        ];
    }
    return result;
  };

  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Work Experiences</Typography>
      </Grid2>
      {workExperiences.map((experience, index) => {
        return (
          <Grid2 xs={12} container key={`${experience.startDate}${index.toString()}`}>
            <Grid2 xs={6}>
              <Input
                id={`${experience.startDate}position${index}`}
                control={control}
                type="text"
                error={!!errors[`${experience.startDate}position${index}`]}
                registerOptions={{ required: true }}
                label="Work Position"
                defaultValue={experience.jobPosition}
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].jobPosition = e.target.value;
                  setWorkExperiences(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Input
                id={`${experience.startDate}company${index}`}
                control={control}
                type="text"
                error={!!errors[`${experience.startDate}company${index}`]}
                registerOptions={{ required: true }}
                label="Company"
                defaultValue={experience.company}
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].company = e.target.value;
                  setWorkExperiences(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextArea
                id={`${experience.startDate}resp${index}`}
                control={control}
                error={!!errors[`${experience.startDate}resp${index}`]}
                registerOptions={{ required: false }}
                label="Responsibilities"
                rows={5}
                defaultValue={experience.responsibilities}
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].responsibilities = e.target.value;
                  setWorkExperiences(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Select
                id={`${experience.startDate}tech${index}`}
                control={control}
                error={!!errors[`${experience.startDate}tech${index}`]}
                registerOptions={{ required: true }}
                label="Technologies"
                labelId={`technogiesLabelId${index}`}
                multiple
                options={generateSkillsOptions()}
                defaultValue={experience.technologies}
                onSelect={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].technologies = e.target.value as string[];
                  setWorkExperiences(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                id={`${experience.startDate}startDate${index}`}
                control={control}
                views={['year']}
                error={!!errors[`${experience.startDate}startDate${index}`]}
                label="Start Date"
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].startDate = new Date(e.target.value);
                  setWorkExperiences(oldData);
                }}
                defaultValue={generateDateString(new Date(experience.startDate))}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                views={['year']}
                id={`${experience.startDate}endDate${index}`}
                control={control}
                error={!!errors[`${experience.startDate}endDate${index}`]}
                label="End Date"
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].endDate = new Date(e.target.value);
                  setWorkExperiences(oldData);
                }}
                defaultValue={generateDateString(new Date(experience.endDate))}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextArea
                id={`${experience.startDate}description${index}`}
                label="Description"
                control={control}
                error={!!errors[`${experience.startDate}description${index}`]}
                rows={5}
                defaultValue={experience.description}
                onType={(e) => {
                  const oldData = [...workExperiences];
                  oldData[index].description = e.target.value;
                  setWorkExperiences(oldData);
                }}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...workExperiences];
            oldData.push({
              jobPosition: '',
              company: '',
              startDate: new Date(),
              endDate: new Date(),
              description: '',
              responsibilities: '',
              technologies: [],
            });
            setWorkExperiences(oldData);
          }}
        >
          Add Work Experience
        </Button>
      </Grid2>
    </>
  );
};

export default WorkExperiences;
