import { Grid, Typography, GridProps } from '@mui/material';
import { colorThemes } from '../../variables/style.variables';

export interface ContainerHeaderProps extends GridProps {
  mainTitle?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  children?: JSX.Element;
}

export const ContainerHeader = ({
  mainTitle,
  subTitle,
  children,
  ...props
}: ContainerHeaderProps) => {
  const titleElement = mainTitle && (
    <Typography fontSize="24px" fontWeight={500} lineHeight="28px" color={colorThemes.black}>
      {mainTitle}
    </Typography>
  );
  const subTitleElement = subTitle && (
    <Typography fontSize="16px" fontWeight={400} color={colorThemes.grey}>
      {subTitle}
    </Typography>
  );

  return (
    <Grid container direction="row" mb={2} width="100%" {...props}>
      <Grid item>
        {typeof mainTitle === 'string' ? titleElement : mainTitle}
        {typeof subTitle === 'string' ? subTitleElement : subTitle}
      </Grid>
      {children && <Grid item>{children}</Grid>}
    </Grid>
  );
};
