import { TableBody } from '@mui/material';
import { getBlind } from '../../../api/blinds.api';
import { Person } from '../../../types/person.types';
import { downloadFile } from '../../../services/file.service';
import { DownloadFileTypes } from '../../../variables/download.variables';
import { modalStore } from '../../../store/modal.store';
import { ModalTypes } from '../../../variables/modal.variables';
import { BlindApiFileFormats } from '../../../variables/blinds-api.variables';
import { CandidatesTableBodyRow } from './candidates-table-body-row';

interface CandidatesTableBodyProps {
  candidates: Person[];
}

export const CandidatesTableBody = ({ candidates }: CandidatesTableBodyProps) => {
  const getFullName = (candidate: Person) => `${candidate.firstName} ${candidate.lastName}`;

  const displayDownloadBlindModal = async (
    candidate: Person,
    blindFileFormat: BlindApiFileFormats,
  ) => {
    const downloadFileType =
      blindFileFormat === BlindApiFileFormats.Pdf ? DownloadFileTypes.Pdf : DownloadFileTypes.Word;
    const candidateFullName = getFullName(candidate);
    const currentISODate = new Date().toISOString();
    const blindFileName = `Blind-${candidate.firstName}_${candidate.lastName}-${currentISODate}.${blindFileFormat}`;
    const blindModal = modalStore.push({
      title: `We are preparing ${candidateFullName}'s blind`,
      content: 'Please wait, we are preparing your blind. It may take few seconds.',
      type: ModalTypes.Confirmation,
      agreeContent: 'Download',
      closeContent: 'Cancel',
      isLoading: true,
    });
    const blind = await getBlind({
      personId: candidate.id,
      fileFormat: blindFileFormat,
    });

    if (!blind) {
      modalStore.update({
        id: blindModal.id,
        isLoading: false,
        title: 'Something went wrong',
        content: `Sorry, something went wrong while preparing ${candidateFullName}'s blind.`,
        agreeProps: { disabled: true },
      });
      return;
    }

    modalStore.update({
      id: blindModal.id,
      isLoading: false,
      title: `${candidateFullName}'s blind is ready`,
      content: 'Click Download to save prepared blind or Cancel to abandon it.',
      onAgree: async () => {
        downloadFile({
          content: blind,
          name: blindFileName,
          type: downloadFileType,
        });
      },
    });
  };

  const candidatesTableBodyRows = candidates.map((candidate) => {
    return (
      <CandidatesTableBodyRow
        key={candidate.id}
        candidate={candidate}
        displayDownloadBlindModal={displayDownloadBlindModal}
      />
    );
  });

  return <TableBody>{candidatesTableBodyRows}</TableBody>;
};
