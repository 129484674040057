import qs from 'query-string';
import { authRequest } from '../services/api.services';
import { parsePaginationHeader } from '../services/pagination.service';
import { GetPersonsArgs, GetPersonsResult, Person } from '../types/person.types';

export const getPersons = async (args: GetPersonsArgs): Promise<GetPersonsResult> => {
  try {
    const params = qs.stringify(args);
    const response = await authRequest<Person[]>({
      url: `/persons?${params}`,
      method: 'get',
    });
    const pagination = parsePaginationHeader(response?.headers?.['x-pagination']);

    return {
      pagination,
      persons: response.data,
    };
  } catch {
    return {
      pagination: false,
      persons: [],
    };
  }
};
