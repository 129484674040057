import AddCandidateForm from '../../forms/Candidate/add-candidate';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';

const EditCandidate = () => {
  return (
    <BDBContainer
      maxWidth="md"
      header={{
        mainTitle: 'Edit candidate',
        subTitle: 'Edit form out to update candidate',
      }}
    >
      <AddCandidateForm />
    </BDBContainer>
  );
};

export default EditCandidate;
