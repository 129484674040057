import Grid2 from '@mui/material/Unstable_Grid2';
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form';
import { FunctionComponent } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import Input from '../../components/FormFields/input';
import Select from '../../components/FormFields/select';
import { ISelectOption } from '../../types/form.types';
import { IStaticFieldsDefaultValues } from '../../types/candidate.types';
import TextArea from '../../components/FormFields/textarea';
import { DatePicker } from '../../components/FormFields/date-picker';

interface IStaticFieldProps {
  control: Control;
  errors: FieldErrorsImpl;
  positions: ISelectOption[];
  seniorities: ISelectOption[];
  projects: ISelectOption[];
  recruiters: ISelectOption[];
  generateDateString: (date: Date) => string;
  staticFieldsDefaultValues: IStaticFieldsDefaultValues;
  setStaticFieldsDefaultValues: (values: IStaticFieldsDefaultValues) => void;
}

const StaticFields: FunctionComponent<IStaticFieldProps> = ({
  control,
  errors,
  positions,
  seniorities,
  projects,
  recruiters,
  generateDateString,
  staticFieldsDefaultValues,
  setStaticFieldsDefaultValues,
}) => {
  return (
    <>
      <Grid2 xs={6}>
        <Input
          id="firstName"
          registerOptions={{ required: true }}
          control={control}
          label="First name"
          type="text"
          error={!!errors.firstName}
          defaultValue={staticFieldsDefaultValues.firstName}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.firstName = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Input
          id="lastName"
          registerOptions={{ required: true }}
          control={control}
          label="Last name"
          type="text"
          error={!!errors.lastName}
          defaultValue={staticFieldsDefaultValues.lastName}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.lastName = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Input
          id="email"
          registerOptions={{ required: true }}
          control={control}
          label="E-mail"
          type="text"
          error={!!errors.email}
          defaultValue={staticFieldsDefaultValues.email}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.email = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Input
          id="phoneNumber"
          registerOptions={{ required: true }}
          control={control}
          label="Phone Number"
          type="number"
          error={!!errors.phoneNumber}
          defaultValue={staticFieldsDefaultValues.phoneNumber}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.phoneNumber = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Controller
          name="jobPositions"
          control={control}
          rules={{ required: true }}
          defaultValue={staticFieldsDefaultValues.jobPositions}
          render={({ field: { onChange, onBlur } }) => (
            <Autocomplete
              freeSolo
              multiple
              onBlur={onBlur}
              value={staticFieldsDefaultValues.jobPositions}
              onChange={(e, value) => {
                onChange(e);
                const oldData = { ...staticFieldsDefaultValues };
                oldData.jobPositions = value as string[];
                setStaticFieldsDefaultValues(oldData);
              }}
              id="jobPositions"
              options={positions}
              renderInput={(params) => (
                <TextField {...params} label="Job positions" error={!!errors.jobPositions} />
              )}
            />
          )}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Input
          id="currentJobPosition"
          registerOptions={{ required: true }}
          control={control}
          label="Current job position"
          type="text"
          error={!!errors.currentJobPosition}
          defaultValue={staticFieldsDefaultValues.currentJobPosition}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.currentJobPosition = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Select
          id="seniorityId"
          registerOptions={{ required: true }}
          control={control}
          label="Seniority"
          multiple={false}
          error={!!errors.seniorityId}
          options={seniorities}
          labelId="seniorityLabel"
          defaultValue={staticFieldsDefaultValues.seniorityId}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Select
          id="projectIds"
          registerOptions={{ required: false }}
          control={control}
          label="Projects"
          multiple
          error={!!errors.projectIds}
          options={projects}
          labelId="projectsLabelId"
          defaultValue={staticFieldsDefaultValues.projectIds}
        />
      </Grid2>
      <Grid2 xs={6}>
        <DatePicker
          id="gdprDate"
          label="Last contact"
          control={control}
          error={!!errors.gdprDate}
          defaultValue={generateDateString(staticFieldsDefaultValues.gdprDate)}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.gdprDate = new Date(e.target.value);
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
      <Grid2 xs={6}>
        <Select
          id="recruiterId"
          registerOptions={{ required: true }}
          control={control}
          label="Recruiter"
          multiple={false}
          error={!!errors.recruiterId}
          options={recruiters}
          labelId="recruiterLabelId"
          defaultValue={staticFieldsDefaultValues.recruiterId}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextArea
          id="description"
          registerOptions={{ required: true }}
          control={control}
          label="Description"
          error={!!errors.description}
          rows={5}
          defaultValue={staticFieldsDefaultValues.description}
          onType={(e) => {
            const oldData = { ...staticFieldsDefaultValues };
            oldData.description = e.target.value;
            setStaticFieldsDefaultValues(oldData);
          }}
        />
      </Grid2>
    </>
  );
};

export default StaticFields;
