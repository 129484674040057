import { makeAutoObservable } from 'mobx';
import { Role } from '../types/roles.types';
import { getRoles } from '../api/roles.api';

class RoleStore {
  loaded = false;

  roles: Role[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchRoles = async () => {
    if (this.loaded && this.roles.length) return;

    this.setLoaded(false);
    const fetchedRoles = await getRoles();

    this.setRoles(fetchedRoles);
    this.setLoaded(true);
  };

  setRoles = (roles: Role[]) => {
    this.roles = roles;
  };

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded;
  };

  getRoleByName = (name: string) => {
    return this.roles.find((role) => role?.name === name);
  };

  getRoleById = (id: string) => {
    return this.roles.find((role) => role?.id === id);
  };
}

export const roleStore = new RoleStore();
