import { AxiosResponse } from 'axios';
import { authRequest, request } from '../services/api.services';
import { PostProjectProps, PatchProjectProps, IProject } from '../types/project.types';

export const getProjects = async (params?: {
  PageNumber: number;
  PageSize: number;
  Name?: string;
}): Promise<IProject[]> => {
  try {
    const response = await request({
      url: `/projects`,
      method: 'get',
      params: params || {},
    });
    return response?.data;
  } catch {
    return [];
  }
};

export const addProject = async (params: PostProjectProps): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: '/projects',
      method: 'post',
      data: params,
    });
    return response;
  } catch {
    return false;
  }
};

export const deleteProject = async (id: string): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: `/projects/${id}`,
      method: 'delete',
    });
    return response;
  } catch {
    return false;
  }
};

export const editProject = async (
  projectId: string,
  payload: PatchProjectProps,
): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: `/projects/${projectId}`,
      method: 'patch',
      data: payload,
    });
    const res = response.data;
    return res;
  } catch {
    return false;
  }
};
