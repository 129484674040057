import { Grid } from '@mui/material';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';

export interface FileHeaderProps {
  file: File;
  onDelete: (file: File) => void;
}

export function FileHeader({ file, onDelete }: FileHeaderProps) {
  return (
    <Grid container alignItems="center" direction="row" spacing={1}>
      <Grid item>{file.name}</Grid>
      <Grid item>
        <BDBButton variant="text" size="small" onClick={() => onDelete(file)}>
          Delete
        </BDBButton>
      </Grid>
    </Grid>
  );
}
