import AddCandidateForm from '../../forms/Candidate/add-candidate';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';

const AddCandidate = () => {
  return (
    <BDBContainer
      maxWidth="md"
      header={{
        mainTitle: 'Create candidate',
        subTitle: 'Fill form out to create new candidate',
      }}
    >
      <AddCandidateForm />
    </BDBContainer>
  );
};

export default AddCandidate;
