import { TableHead, TableRow, TableCell, Grid } from '@mui/material';
import { SelectFilter } from '../../../layouts/SelectFilter/select-filter';
import { CandidatesFilters } from '../candidates';

interface CandidatesTableHeadCell {
  id: string;
  label: string;
  minWidth: string;
  width?: string;
  filter?: JSX.Element;
}

interface CandidatesTableHeadProps {
  filters: CandidatesFilters;
  handleChangeFilters: (value: string[], filter: keyof CandidatesFilters) => void;
  handleCloseFilter: (isChanged: boolean, f: keyof CandidatesFilters) => unknown;
}

export const CandidatesTableHead = ({
  filters,
  handleChangeFilters,
  handleCloseFilter,
}: CandidatesTableHeadProps) => {
  const candidatesTableHeadCells: CandidatesTableHeadCell[] = [
    { id: 'name', label: 'Name', minWidth: '150px', width: '200px' },
    { id: 'email', label: 'Email', minWidth: '250px', width: '300px' },
    {
      id: 'projects',
      label: 'Projects',
      minWidth: '220px',
      width: '220px',
      filter: (
        <SelectFilter
          options={filters.projects.options}
          disabled={!filters.projects.options.length}
          onChange={(value) => handleChangeFilters(value, 'projects')}
          value={filters.projects.value}
          onClose={({ isChanged }) => handleCloseFilter(isChanged, 'projects')}
        />
      ),
    },
    {
      id: 'skills',
      label: 'Skills',
      minWidth: '320px',
      width: '320px',
      filter: (
        <SelectFilter
          options={filters.skills.options}
          disabled={!filters.skills.options.length}
          onChange={(value) => handleChangeFilters(value, 'skills')}
          value={filters.skills.value}
          onClose={({ isChanged }) => handleCloseFilter(isChanged, 'skills')}
        />
      ),
    },
    {
      id: 'recruiter',
      label: 'Recruiter',
      minWidth: '150px',
      width: '200px',
      filter: (
        <SelectFilter
          options={filters.recruiters.options}
          disabled={!filters.recruiters.options.length}
          onChange={(value) => handleChangeFilters(value, 'recruiters')}
          value={filters.recruiters.value}
          onClose={({ isChanged }) => handleCloseFilter(isChanged, 'recruiters')}
        />
      ),
    },
    { id: 'actions', label: '', minWidth: '50px', width: '50px' },
  ];

  return (
    <TableHead>
      <TableRow>
        {candidatesTableHeadCells.map((candidatesTableHeadCell) => (
          <TableCell
            key={candidatesTableHeadCell.id}
            sx={{
              minWidth: candidatesTableHeadCell.minWidth,
              width: candidatesTableHeadCell?.width || 'auto',
              maxWidth: candidatesTableHeadCell?.width || 'auto',
            }}
          >
            <Grid container direction="row" alignItems="center" columnSpacing={1}>
              <Grid item sx={{ fontWeight: 500 }}>
                {candidatesTableHeadCell.label}
              </Grid>
              <Grid item>{candidatesTableHeadCell?.filter}</Grid>
            </Grid>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
