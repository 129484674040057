export const mockData = [
  {
    firstName: 'Marcin ',
    lastName: 'Banaszkiewicz',
    email: 'mockMail1@gmail.com',
    phoneNumber: '123456789',
    id: '1',
    roleId: '111',
    userId: '222',
  },
  {
    firstName: 'Jan',
    lastName: 'Kowalski',
    email: 'mockMail2@gmail.com',
    phoneNumber: '987654321',
    id: '2',
    roleId: '333',
    userId: '444',
  },
];
