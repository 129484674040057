import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, Paper, TableRow } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react';
import InfoIcon from '@mui/icons-material/Info';
import { getApplicants } from '../../api/application.api';
import { ApplicationTableHead } from './components/application-table-head';
import { GetApplicationResponseItem } from '../../types/application.types';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { useGenerateApplyUrlModal } from '../../hooks/modals/useGenerateApplyUrlModal';
import { modalStore } from '../../store/modal.store';

const usersLoadingSkeleton = (
  <>
    {[1, 2, 3, 4, 5].map(() => (
      <TableRow key={Math.random()}>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
      </TableRow>
    ))}
  </>
);

function Applications() {
  const [applicants, setApplicants] = useState<GetApplicationResponseItem[]>([]);
  const [loading, setLoading] = useState(false);
  const { openGenerateApplyUrlModal } = useGenerateApplyUrlModal({ modalStore });

  const fetchApplicants = async () => {
    setLoading(true);

    const users = await getApplicants();
    setApplicants(users);
    setLoading(false);
  };

  useEffect(() => {
    fetchApplicants();
  }, []);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{
        mainTitle: 'Applications',
        subTitle: 'List of applications',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        children: (
          <BDBButton variant="text" fontWeight={400} onClick={openGenerateApplyUrlModal}>
            <InfoIcon sx={{ marginRight: '4px' }} />
            Apply urls
          </BDBButton>
        ),
      }}
    >
      <Paper>
        <TableContainer>
          <Table>
            <ApplicationTableHead />
            <TableBody>
              {loading
                ? usersLoadingSkeleton
                : applicants.map((user) => {
                    return (
                      <TableRow key={Math.random()}>
                        <TableCell>
                          {user.firstName} {user.lastName}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.availability}</TableCell>
                        <TableCell>{user.financialExpectations}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </BDBContainer>
  );
}

export default observer(Applications);
