import { Grid } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { colorThemes } from '../../variables/style.variables';

export const BDBCircularProgress = ({ ...props }: CircularProgressProps) => {
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      sx={{
        height: 400,
      }}
      spacing={3}
    >
      <Grid item>
        <CircularProgress
          size={50}
          sx={{
            color: colorThemes.primary,
          }}
          {...props}
        />
      </Grid>
    </Grid>
  );
};
