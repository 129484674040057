import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';
import { modalStore } from '../../../../../store/modal.store';
import { ModalTypes } from '../../../../../variables/modal.variables';
import { boardStore } from '../../../../../store/board.store';
import { BDBButton } from '../../../../../layouts/BDB-Button/bdb-button';

export const DeleteBoardButton = observer(() => {
  const { board } = boardStore;
  const navigate = useNavigate();

  const onDeleteBoard = async () => {
    modalStore.push({
      title: 'Delete confirmation',
      maxWidth: 'xs',
      content: `Are you sure you want to delete ${board?.name}?`,
      type: ModalTypes.Confirmation,
      onAgree: async (currentModal) => {
        modalStore.update({ ...currentModal, isLoading: true });
        const response = await boardStore.deleteBoard();
        if (response) {
          modalStore.push({
            title: 'Board deleted successfully',
            content: `${board?.name} was deleted`,
            type: ModalTypes.Info,
          });
          navigate('/dashboard');
        } else {
          modalStore.push({
            title: 'Something went wrong',
            content: 'There was error while removing board.',
            type: ModalTypes.Info,
          });
        }
      },
    });
  };
  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <BDBButton maxWidth="150px" onClick={onDeleteBoard}>
          Delete board
        </BDBButton>
      </Grid>
    </Grid>
  );
});
