import { TableHead, TableRow, TableCell } from '@mui/material';

interface ProjectTableHeadCell {
  id: string;
  label: string;
  minWidth: string;
  width?: string;
}

const projectTableHeadCells: readonly ProjectTableHeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: '200px',
  },
  {
    id: 'owner',
    label: 'Owner',
    minWidth: '350px',
  },
  {
    id: 'actions',
    label: '',
    minWidth: '190px',
    width: '190px',
  },
];

export function ProjectTableHead() {
  return (
    <TableHead>
      <TableRow>
        {projectTableHeadCells.map((projectTableHeadCell) => (
          <TableCell
            key={projectTableHeadCell.id}
            sx={{
              minWidth: projectTableHeadCell.minWidth,
              width: projectTableHeadCell.width || 'auto',
            }}
          >
            {projectTableHeadCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
