import { TableHead, TableRow, TableCell } from '@mui/material';

interface EmployeesTableHeadCell {
  id: string;
  label: string;
  minWidth: string;
  width?: string;
}

const employeesTableHeadCells: readonly EmployeesTableHeadCell[] = [
  { id: 'name', label: 'Name', minWidth: '200px' },
  { id: 'email', label: 'Email', minWidth: '350px' },
  { id: 'phoneNumber', label: 'Phone number', minWidth: '130px' },
  { id: 'actions', label: '', minWidth: '290px', width: '290px' },
];

export const EmployeesTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {employeesTableHeadCells.map((employeeTableHeadCell) => (
          <TableCell
            key={employeeTableHeadCell.id}
            sx={{
              minWidth: employeeTableHeadCell.minWidth,
              width: employeeTableHeadCell?.width || 'auto',
            }}
          >
            {employeeTableHeadCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
