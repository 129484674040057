import { useState } from 'react';
import { Grid } from '@mui/material';
import { mockData } from './mockData';
import { IEmployee } from '../../types/employee.types';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { EmployeesTable } from './components/employees-table';

const Employees = () => {
  const [employees] = useState<IEmployee[]>(mockData);
  const [isLoadingEmployees] = useState(false);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{
        mainTitle: 'Employees',
        subTitle: `List of company's employees`,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Grid container direction="column" rowSpacing={2}>
        <Grid item width="100%">
          <EmployeesTable employees={employees} isLoadingEmployees={isLoadingEmployees} />
        </Grid>
      </Grid>
    </BDBContainer>
  );
};

export default Employees;
