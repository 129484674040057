import { FC, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Input from '../../../components/FormFields/input';
import { FormErrors } from '../../../components/FormErrors/form-errors';
import { BDBTypography } from '../../../layouts/BDB-Typography/bdb-typography';
import { BDBGrid } from '../../../layouts/BDB-Grid/bdb-grid';
import { UploadButton } from '../../../components/UploadButton/upload-button';
import { FileTypesId } from '../../../types/file.types';
import { BDBButton } from '../../../layouts/BDB-Button/bdb-button';

export const Benefits: FC<{ setHobbyFile: (hobbyFile: FormData | null) => void }> = ({
  setHobbyFile,
}) => {
  const [showHobby, setShowHobby] = useState(true);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { multiSport: multiSportError, luxMed: luxMedError } = errors;
  return (
    <BDBGrid>
      <Grid item xs={12}>
        <BDBTypography variant="h5">Benefits</BDBTypography>
      </Grid>
      <Grid item xs={6}>
        <Input
          id="multiSport"
          registerOptions={{ required: true }}
          control={control}
          label="Multisport"
          type="text"
          error={!!multiSportError}
        />
        {multiSportError?.message && <FormErrors messages={[String(multiSportError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <Input
          id="luxMed"
          registerOptions={{ required: true }}
          control={control}
          label="Luxmed"
          type="text"
          error={!!luxMedError}
        />
        {luxMedError?.message && <FormErrors messages={[String(luxMedError?.message)]} />}
      </Grid>
      <Grid container item xs={4} spacing={2} direction="column" alignItems="flex-start">
        <Grid item>
          <BDBButton
            type="button"
            variant="text"
            onClick={() => (showHobby ? setShowHobby(false) : setShowHobby(true))}
            endIcon={<AddCircleOutlineIcon />}
          >
            {showHobby ? `Hide hobby` : `Show hobby`}
          </BDBButton>
        </Grid>
        {showHobby && (
          <Grid item xs={12}>
            <UploadButton
              name="Hobby upload"
              onChange={async (e) => {
                const target = e.target as HTMLInputElement;
                const fileArray = target.files;
                if (fileArray) {
                  const formData = new FormData();
                  formData.append('Files', fileArray[0]);
                  formData.append('FileTypeId', FileTypesId.Hobby);
                  setHobbyFile(formData);
                }
              }}
              onDelete={() => {
                setHobbyFile(null);
              }}
            />
          </Grid>
        )}
      </Grid>
    </BDBGrid>
  );
};
