import { Chip, Popover, Stack, ClickAwayListener, Grid } from '@mui/material';
import { useState, MouseEvent } from 'react';

interface ChipsStackProps {
  id: string;
  labels: string[];
  max?: number;
}

export const ChipsStack = ({ id, labels, max = -1 }: ChipsStackProps) => {
  const [isPopoverPesistent, setIsPopoverPersistent] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const labelsAmount = labels.length;
  const isMax = max !== -1;
  const differenceBetweenMaxAndLabels = labelsAmount - max;
  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (isPopoverPesistent) return;

    setPopoverAnchorEl(null);
  };

  const handlePopoverForceClose = () => {
    setIsPopoverPersistent(false);
    setPopoverAnchorEl(null);
  };

  const handleAdditionalChipClick = () => {
    setIsPopoverPersistent(true);
  };

  const renderedChips = labels.map((label, index) => {
    const key = `${label}-${index}`;

    if (isMax && index >= max) return null;

    return <Chip key={key} label={label} />;
  });

  const renderRemainingChips = () => {
    const remainingChips = [];

    for (let i = max; i < labelsAmount; i += 1) {
      const key = `${labels[i]}-${i}`;

      remainingChips.push(<Chip key={key} label={labels[i]} size="small" />);
    }

    return remainingChips;
  };

  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      {renderedChips}
      {isMax && labelsAmount > max && (
        <ClickAwayListener onClickAway={handlePopoverForceClose}>
          <Grid>
            <Chip
              label={`+${differenceBetweenMaxAndLabels}`}
              aria-owns={isPopoverOpen ? id : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleAdditionalChipClick}
            />
            <Popover
              id={id}
              open={isPopoverOpen}
              anchorEl={popoverAnchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: -8,
                horizontal: 'center',
              }}
              sx={{
                pointerEvents: 'none',
              }}
              PaperProps={{
                style: {
                  padding: '8px',
                  maxWidth: '320px',
                },
              }}
              onClose={handlePopoverForceClose}
            >
              <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                {renderRemainingChips()}
              </Stack>
            </Popover>
          </Grid>
        </ClickAwayListener>
      )}
    </Stack>
  );
};
