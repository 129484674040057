import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import Boards from './components/boards';

const Dashboard = () => {
  return (
    <BDBContainer
      maxWidth="xl"
      header={{ mainTitle: 'Dashboard', subTitle: 'Contribute to your workspaces' }}
    >
      <Boards />
    </BDBContainer>
  );
};

export default Dashboard;
