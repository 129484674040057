import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { IFormValues, ISelectOption } from '../../types/form.types';
import {
  addCandidate,
  editCandidate,
  getCandidateById,
  getPositions,
  getRecruiterList,
  getSeniorities,
} from '../../api/candidate.api';
import { getLanguageLevels, getLanguages } from '../../api/languages.api';
import { getSkills } from '../../api/skills.api';
import { getProjects } from '../../api/project.api';
import {
  ICandidateParams,
  ICertificate,
  ICvFile,
  IEducations,
  IPersonDetailed,
  IProjectExperience,
  IStaticFieldsDefaultValues,
  IWorkExperiences,
} from '../../types/candidate.types';
import { ISelectedLanguages } from '../../types/language.types';
import { ISelectedSkills, ISkillGroup } from '../../types/skill.types';
import StaticFields from './staticsFields';
import Skills from './skills';
import Languages from './languages';
import WorkExperiences from './work-experiences';
import RemarksAndSubmit from './remarks-and-submit';
import Educations from './educations';
import Certificates from './certificates';
import ProjectExperience from './project-experience';
import { Roles } from '../../types/user.types';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';
import {
  DefaultSelectedLanguageObj,
  DefaultSelectedSkillsObj,
} from '../../variables/candidate.variables';

const AddCandidateForm = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [positions, setPositions] = useState<ISelectOption[]>([]);
  const [seniorities, setSeniorities] = useState<ISelectOption[]>([]);
  const [projects, setProjects] = useState<ISelectOption[]>([]);
  const [recruiters, setRecrutiers] = useState<ISelectOption[]>([]);
  const [skills, setSkills] = useState<ISkillGroup[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<ISelectedSkills[]>([]);
  const [languages, setLanguages] = useState<ISelectOption[]>([]);
  const [languageLevels, setLanguageLevels] = useState<ISelectOption[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<ISelectedLanguages[]>([]);
  const [workExperiences, setWorkExperiences] = useState<IWorkExperiences[]>([]);
  const [educations, setEducations] = useState<IEducations[]>([]);
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [projectExperiences, setProjectExperiences] = useState<IProjectExperience[]>([]);
  const [cvFile, setCvFile] = useState<ICvFile | null>(null);
  const [staticFieldsDefaultValues, setStaticFieldsDefaultValues] =
    useState<IStaticFieldsDefaultValues>({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      jobPositions: [],
      currentJobPosition: '',
      seniorityId: '',
      projectIds: [],
      gdprDate: new Date(),
      recruiterId: '',
      remarks: '',
      description: '',
    });
  const [candidate, setCandidate] = useState<IPersonDetailed | null>(null);
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = async (values: IFormValues) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      remarks,
      description,
      gdprDate,
      currentJobPosition,
      seniorityId,
      recruiterId,
      projectIds,
    } = values;

    const jobPositionsRequest = staticFieldsDefaultValues.jobPositions
      .map((job: string | { value: string }) => {
        if (typeof job === 'string') {
          return {
            newJobPositionName: job,
          };
        }

        return {
          existingJobPositionId: job.value,
        };
      })
      .flat();

    const skillsRequest = selectedSkills
      .map((selectedSkill) => {
        const skillGroupId = skills[selectedSkill.skillGroupIndex].id;

        return selectedSkill.skillId.map((skill: string | { value: string }) => {
          if (typeof skill === 'string') {
            return {
              skillGroupIdForNewSkill: skillGroupId,
              newSkillName: skill,
            };
          }

          return {
            existingSkillId: skill.value,
          };
        });
      })
      .flat();

    const payload: ICandidateParams = {
      firstName,
      lastName,
      email,
      phoneNumber,
      remarks,
      description,
      gdprDate: new Date(gdprDate),
      seniorityId,
      recruiterId,
      jobPositions: jobPositionsRequest,
      projectIds,
      candidate: { currentJobPosition, projectExperiences, cvFile },
      languages: selectedLanguages,
      skills: skillsRequest,
      certificates,
      educations,
      workExperiences,
    };

    const response = id ? await editCandidate({ id, data: payload }) : await addCandidate(payload);
    if (response) {
      modalStore.push({
        title: 'Candidate',
        content: `Candidate ${id ? 'Updated' : 'Created'}`,
        type: ModalTypes.Info,
      });
      navigate('/candidates');
    } else {
      modalStore.push({
        title: 'Something went wrong',
        content: `There was error while ${id ? 'editing' : 'creating'} candidate.`,
        type: ModalTypes.Info,
      });
    }
  };

  const fetchPositions = async () => {
    const data = await getPositions();
    if (data) {
      const result = data.map((position) => ({ label: position.name, value: position.id }));
      setPositions(result);
    }
  };

  const fetchSeniorities = async () => {
    const data = await getSeniorities();
    if (data) {
      const result = data.map((seniority) => ({ label: seniority.name, value: seniority.id }));
      setSeniorities(result);
    }
  };

  const fetchProjects = async () => {
    const data = await getProjects();
    if (data) {
      const result = data.map((project) => ({ label: project.name, value: project.id }));
      setProjects(result);
    }
  };

  const fetchRecruiters = async () => {
    const users = await getRecruiterList(Roles.HR);
    if (users) {
      setRecrutiers(
        users.map((user) => {
          return { label: `${user.firstName} ${user.lastName}`, value: user.userId };
        }),
      );
    }
  };

  const fetchSkills = async () => {
    const data = await getSkills();
    if (data) {
      setSkills(data);
    }
  };

  const generateDateString = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return [year, `${month < 10 ? 0 : ''}${month}`, `${day < 10 ? 0 : ''}${day}`].join('-');
  };

  const fetchLanguages = async () => {
    const languagesResponse = await getLanguages();
    if (languagesResponse) {
      setLanguages(
        languagesResponse.map((language) => {
          return { label: language.name, value: language.id };
        }),
      );
    }

    const languageLevelsResponse = await getLanguageLevels();
    if (languageLevelsResponse) {
      setLanguageLevels(
        languageLevelsResponse.map((language) => {
          return { label: language.name, value: language.id };
        }),
      );
    }
  };

  const fetchCandidate = async () => {
    const { status, data } = await getCandidateById(id as string);
    if (status === 200) {
      const jobPositions = data.jobPositions.map((position) => position.name);
      setStaticFieldsDefaultValues({
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        jobPositions,
        currentJobPosition: data.candidate.currentJobPosition,
        seniorityId: data.seniority.id,
        projectIds: data.projects.map((project) => project.id),
        gdprDate: new Date(data.gdprDate),
        recruiterId: data.recruiter.userId,
        remarks: data.remarks,
        description: data.description,
      });
      setCandidate(data);
      setEducations(data.educations);
      setWorkExperiences(data.workExperiences);
      setProjectExperiences(data.candidate.projectExperiences);
      setCertificates(data.certificates);
    }
  };

  useEffect(() => {
    fetchPositions();
    fetchSeniorities();
    fetchProjects();
    fetchRecruiters();
    fetchSkills();
    fetchLanguages();
    if (id) {
      fetchCandidate();
    } else {
      setSelectedLanguages([DefaultSelectedLanguageObj]);
      setSelectedSkills([DefaultSelectedSkillsObj]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (staticFieldsDefaultValues.id.length) {
      setLoading(false);
    }
  }, [staticFieldsDefaultValues.id]);

  return loading ? (
    <div>Loading...</div>
  ) : (
    <Grid2 onSubmit={handleSubmit(onSubmit)} component="form" spacing={2} container>
      <StaticFields
        {...{
          control,
          errors,
          positions,
          seniorities,
          projects,
          recruiters,
          generateDateString,
          staticFieldsDefaultValues,
          setStaticFieldsDefaultValues,
        }}
      />
      {skills.length > 0 && (
        <Skills
          {...{ control, errors, selectedSkills, skills, setSelectedSkills, candidate, id }}
        />
      )}
      {languages.length > 0 && languageLevels.length > 0 && (
        <Languages
          {...{
            control,
            errors,
            selectedLanguages,
            setSelectedLanguages,
            languages,
            languageLevels,
            candidate,
            id,
          }}
        />
      )}
      <Educations {...{ control, errors, educations, setEducations, generateDateString }} />
      {skills.length > 0 && (
        <WorkExperiences
          {...{ control, errors, workExperiences, setWorkExperiences, skills, generateDateString }}
        />
      )}
      <ProjectExperience
        {...{ control, errors, projectExperiences, setProjectExperiences, generateDateString }}
      />
      <Certificates {...{ control, errors, certificates, setCertificates }} />
      <RemarksAndSubmit
        {...{ control, errors, setCvFile }}
        remarks={staticFieldsDefaultValues.remarks}
        buttonText={id ? 'Save' : 'Create'}
      />
    </Grid2>
  );
};

export default AddCandidateForm;
