import { Grid, Card, CardActionArea, Typography } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { colorThemes } from '../../../variables/style.variables';

interface BoardCreateProps {
  onClick: () => void;
}

export const BoardCreate = ({ onClick }: BoardCreateProps) => {
  return (
    <Grid xs={12} sm={6} md={4} xl={3} item>
      <Card sx={{ height: '108px' }}>
        <CardActionArea
          onClick={onClick}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              <AddCircleOutlinedIcon sx={{ color: colorThemes.primary }} />
            </Grid>
            <Grid item>
              <Typography>Add Board</Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
