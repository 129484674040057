export enum Roles {
  HR = 'HR',
  ADMIN = 'Admin',
  GUEST = 'Guest',
}

export interface IUser {
  appuserId: string;
  userId: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  roles: { id: string; name: string }[];
}

export interface IDecodedToken {
  exp: number;
  iat: number;
  nbf: number;
  role: string;
  sub: string;
  user_id: string;
}
