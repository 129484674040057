import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, Paper, TableRow, Grid } from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProjectTableHead } from './components/projects-table-head';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { roleStore } from '../../store/role.store';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { deleteProject, getProjects } from '../../api/project.api';
import { ProjectProps, IProject } from '../../types/project.types';
import AddEditProject from '../../forms/Project/add-edit-project';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';

const initialValueProject = {
  id: '',
  name: '',
  ownerId: '',
};

const usersLoadingSkeleton = (
  <>
    {[1, 2, 3, 4, 5].map(() => (
      <TableRow key={Math.random()}>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
      </TableRow>
    ))}
  </>
);

function Projects() {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [defaultValueProject, setDefaultValueProject] = useState<ProjectProps>(initialValueProject);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchProjects = async () => {
    setLoading(true);
    const data = await getProjects();
    if (data) {
      setProjects(data);
    }
    setLoading(false);
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onAddProject = () => {
    setDefaultValueProject(initialValueProject);
    setOpenDialog(true);
  };
  const onEdit = (element: IProject) => {
    const { name, owner, id } = element;
    const project: ProjectProps = {
      id,
      name,
      ownerId: owner.userId,
    };
    setDefaultValueProject(project);
    setOpenDialog(true);
  };

  const onDeleteProject = async (project: IProject) => {
    const { name, id } = project;

    modalStore.push({
      title: 'Delete confirmation',
      content: `Are you sure you want to delete ${name} project?`,
      type: ModalTypes.Confirmation,
      onAgree: async (currentModal) => {
        modalStore.update({ ...currentModal, isLoading: true });

        const response = await deleteProject(id);
        await fetchProjects();

        if (response) {
          modalStore.push({
            title: 'Project deleted',
            content: `Project ${name} was deleted`,
            type: ModalTypes.Info,
          });
        } else {
          modalStore.push({
            title: 'Something went wrong',
            content: 'There was error while removing project.',
            type: ModalTypes.Info,
          });
        }
      },
    });
  };

  useEffect(() => {
    roleStore.fetchRoles();
    fetchProjects();
  }, []);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{
        mainTitle: 'Projects',
        subTitle: 'List of projects on the application',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        children: (
          <BDBButton variant="text" fontWeight={400} onClick={onAddProject}>
            <AddCircleOutlinedIcon sx={{ marginRight: '4px' }} />
            Create
          </BDBButton>
        ),
      }}
    >
      <Paper>
        <TableContainer>
          <Table>
            <ProjectTableHead />
            <TableBody>
              {loading
                ? usersLoadingSkeleton
                : projects.map((project) => {
                    return (
                      <TableRow key={project.name}>
                        <TableCell>{project.name}</TableCell>
                        <TableCell>
                          {project.owner.firstName} {project.owner.lastName}
                        </TableCell>
                        <TableCell>
                          <Grid container direction="row">
                            <Grid item>
                              <BDBButton
                                variant="text"
                                fontWeight={400}
                                onClick={() => onEdit(project)}
                                fontSize="14px"
                              >
                                <EditIcon sx={{ marginRight: '4px' }} />
                                Edit
                              </BDBButton>
                            </Grid>
                            <Grid item>
                              <BDBButton
                                variant="text"
                                fontWeight={400}
                                onClick={() => onDeleteProject(project)}
                                fontSize="14px"
                              >
                                <DeleteIcon sx={{ marginRight: '4px' }} />
                                Delete
                              </BDBButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <AddEditProject
          {...{ setDefaultValueProject, defaultValueProject }}
          openDialog={openDialog}
          onClose={onCloseDialog}
          onSuccess={fetchProjects}
        />
      </Paper>
    </BDBContainer>
  );
}

export default observer(Projects);
