interface UpdateOrdinalArgs {
  currentOrdinal: number;
  newOrdinal: number;
  collection: Array<{ ordinal: number }>;
}

export const updateOrdinal = <T>({
  currentOrdinal,
  newOrdinal,
  collection,
}: UpdateOrdinalArgs): Array<T> => {
  if (currentOrdinal === newOrdinal) return collection as Array<T>;
  if (newOrdinal > currentOrdinal) {
    return collection.map((entry) => {
      if (entry.ordinal > currentOrdinal && entry.ordinal <= newOrdinal) entry.ordinal -= 1;
      else if (entry.ordinal === currentOrdinal) entry.ordinal = newOrdinal;
      return entry;
    }) as Array<T>;
  }
  if (newOrdinal < currentOrdinal) {
    return collection.map((entry) => {
      if (entry.ordinal < currentOrdinal && entry.ordinal >= newOrdinal) entry.ordinal += 1;
      else if (entry.ordinal === currentOrdinal) entry.ordinal = newOrdinal;
      return entry;
    }) as Array<T>;
  }
  return [];
};
