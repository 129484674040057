import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useNavigate } from 'react-router-dom';
import { useState, MouseEvent } from 'react';
import { Person, PersonProject } from '../../../types/person.types';
import { ISkillGroup } from '../../../types/skill.types';
import { ChipsStack } from '../../../layouts/ChipsStack/chips-stack';
import { BlindApiFileFormats } from '../../../variables/blinds-api.variables';

interface CandidatesTableBodyRowProps {
  candidate: Person;
  displayDownloadBlindModal: (candidate: Person, blindFileFormat: BlindApiFileFormats) => void;
}

export const CandidatesTableBodyRow = ({
  candidate,
  displayDownloadBlindModal,
}: CandidatesTableBodyRowProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const candidateFullName = `${candidate.firstName} ${candidate.lastName}`;
  const recruiterFullName = `${candidate.recruiter.firstName} ${candidate.recruiter.lastName}`;
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownloadBlindModal = (
    candidateData: Person,
    blindFileFormat: BlindApiFileFormats,
  ) => {
    handleCloseMenu();
    displayDownloadBlindModal(candidateData, blindFileFormat);
  };

  const getCandidateProjects = (candidateProjects: PersonProject[]) =>
    candidateProjects.map((project) => project.name);

  const getCandidateSkills = (candidateSkillGroups: ISkillGroup[]) => {
    const skills = candidateSkillGroups.map((skillGroup) =>
      skillGroup.skills.map((skill) => skill.name),
    );
    const flatSkills = skills.flat();

    return flatSkills;
  };

  return (
    <TableRow>
      <TableCell>{candidateFullName}</TableCell>
      <TableCell>{candidate.email}</TableCell>
      <TableCell>
        <ChipsStack
          id={`${candidate.id}-chips-projects`}
          labels={getCandidateProjects(candidate.projects)}
          max={2}
        />
      </TableCell>
      <TableCell>
        <ChipsStack
          id={`${candidate.id}-chips-skills`}
          labels={getCandidateSkills(candidate.skillGroups)}
          max={3}
        />
      </TableCell>
      <TableCell>{recruiterFullName}</TableCell>
      <TableCell>
        <Grid container direction="row" alignItems="center">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={isMenuOpen ? 'long-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 0, horizontal: 'right' }}
          >
            <MenuItem onClick={() => navigate(`/candidates/edit/${candidate.id}`)}>
              <ListItemIcon>
                <EditIcon sx={{ marginRight: '4px' }} />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </MenuItem>
            <MenuItem onClick={() => navigate(`/persons/${candidate.id}/employee`)}>
              <ListItemIcon>
                <SendIcon sx={{ marginRight: '4px' }} />
              </ListItemIcon>
              <ListItemText primary="Create employee" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleDownloadBlindModal(candidate, BlindApiFileFormats.Pdf)}>
              <ListItemIcon>
                <PictureAsPdfOutlinedIcon sx={{ marginRight: '4px' }} />
              </ListItemIcon>
              <ListItemText primary="Blind (PDF)" />
            </MenuItem>
            <MenuItem onClick={() => handleDownloadBlindModal(candidate, BlindApiFileFormats.Word)}>
              <ListItemIcon>
                <FileDownloadIcon sx={{ marginRight: '4px' }} />
              </ListItemIcon>
              <ListItemText primary="Blind (Word)" />
            </MenuItem>
          </Menu>
        </Grid>
      </TableCell>
    </TableRow>
  );
};
