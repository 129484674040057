import { authRequest } from '../services/api.services';
import type { Role } from '../types/roles.types';

export const getRoles = async (): Promise<Role[]> => {
  try {
    const response = await authRequest<{ roles: Role[] }>({
      url: `/roles`,
      method: 'get',
    });

    return response?.data?.roles;
  } catch {
    return [];
  }
};
