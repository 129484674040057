import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';
import { FormErrors } from '../../components/FormErrors/form-errors';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { Dropzone } from '../../components/Dropzone/dropzone';
import { ApplicationPostProps } from '../../types/application.types';
import { postAddApplication } from '../../api/application.api';
import { modalStore } from '../../store/modal.store';
import { uistore } from '../../store/ui.store';
import { ModalTypes } from '../../variables/modal.variables';

export const Apply = observer(() => {
  const { projectId } = useParams();
  const [isProcessing, setIsProcessing] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplicationPostProps>();

  const {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    availability: availabilityError,
    financialExpectations: financialExpectationsError,
    message: messageError,
  } = errors;
  const onSubmit = async (values: ApplicationPostProps) => {
    const { firstName, lastName, email, availability, financialExpectations, message } = values;
    const payload: ApplicationPostProps = {
      firstName,
      lastName,
      email,
      availability,
      financialExpectations,
      message,
      projectId,
    };
    setIsProcessing(true);
    const response = await postAddApplication(payload);
    if (response) {
      modalStore.push({
        title: 'Send',
        content: `Your application was send`,
        type: ModalTypes.Info,
      });
    } else {
      modalStore.push({
        title: 'Something went wrong',
        content: 'There was error while sending application.',
        type: ModalTypes.Info,
      });
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    uistore.setIsHiddenAppNav(true);

    return () => {
      uistore.setIsHiddenAppNav(false);
    };
  }, []);

  return (
    <BDBContainer
      maxWidth="sm"
      header={{
        mainTitle: 'Apply for job',
        subTitle: 'Fill form out to apply for job',
      }}
    >
      <form id="apply-for-job" onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          component="fieldset"
          disabled={isProcessing}
          border="none"
          direction="column"
          rowSpacing={3}
          paddingY={2}
        >
          <Grid item container direction="row" columnSpacing={3}>
            <Grid item xs>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField label="First name" onChange={onChange} onBlur={onBlur} fullWidth />
                )}
              />
              {firstNameError?.message && (
                <FormErrors messages={[String(firstNameError?.message)]} />
              )}
            </Grid>
            <Grid item xs>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField label="Last name" onChange={onChange} onBlur={onBlur} fullWidth />
                )}
              />
              {lastNameError?.message && <FormErrors messages={[String(lastNameError?.message)]} />}
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, onBlur } }) => (
                <BDBTextField label="Email" onChange={onChange} onBlur={onBlur} fullWidth />
              )}
            />
            {emailError?.message && <FormErrors messages={[String(emailError?.message)]} />}
          </Grid>
          <Grid item container direction="row" columnSpacing={3}>
            <Grid item xs>
              <Controller
                name="availability"
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField
                    label="Availability"
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                  />
                )}
              />
              {availabilityError?.message && (
                <FormErrors messages={[String(availabilityError?.message)]} />
              )}
            </Grid>
            <Grid item xs>
              <Controller
                name="financialExpectations"
                control={control}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField
                    label="Financial Expectations"
                    onChange={onChange}
                    onBlur={onBlur}
                    fullWidth
                  />
                )}
              />
              {financialExpectationsError?.message && (
                <FormErrors messages={[String(financialExpectationsError?.message)]} />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              name="message"
              control={control}
              render={({ field: { onChange, onBlur } }) => (
                <BDBTextField
                  label="Message"
                  onChange={onChange}
                  onBlur={onBlur}
                  fullWidth
                  multiline
                  rows={5}
                />
              )}
            />
            {messageError?.message && <FormErrors messages={[String(messageError?.message)]} />}
          </Grid>
          <Grid item>
            <Dropzone />
          </Grid>
        </Grid>
      </form>
      <Grid item container justifyContent="center">
        <BDBButton maxWidth="150px" type="submit" form="apply-for-job" loading={isProcessing}>
          Sumbit
        </BDBButton>
      </Grid>
    </BDBContainer>
  );
});
