import { FunctionComponent } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import { IProjectExperience } from '../../types/candidate.types';
import Input from '../../components/FormFields/input';
import TextArea from '../../components/FormFields/textarea';
import { DatePicker } from '../../components/FormFields/date-picker';

interface IProjectExperienceProps {
  control: Control;
  errors: FieldErrorsImpl;
  projectExperiences: IProjectExperience[];
  setProjectExperiences: (experiences: IProjectExperience[]) => void;
  generateDateString: (date: Date) => string;
}

const ProjectExperience: FunctionComponent<IProjectExperienceProps> = ({
  control,
  errors,
  projectExperiences,
  setProjectExperiences,
  generateDateString,
}) => {
  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Project Experiences</Typography>
      </Grid2>
      {projectExperiences.map((experience, index) => {
        return (
          <Grid2 xs={12} container key={`${experience.dateStart}${index.toString()}`}>
            <Grid2 xs={12}>
              <Input
                type="text"
                id={`projectExperienceName${index}`}
                error={!!errors[`projectExperienceName${index}`]}
                label="Name"
                control={control}
                registerOptions={{ required: true }}
                defaultValue={experience.name}
                onType={(e) => {
                  const oldData = [...projectExperiences];
                  oldData[index].name = e.target.value;
                  setProjectExperiences(oldData);
                }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                views={['year']}
                id={`projectExperienceStartDate${index}`}
                error={!!errors[`projectExperienceStartDate${index}`]}
                label="Start Date"
                control={control}
                onType={(e) => {
                  const oldData = [...projectExperiences];
                  oldData[index].dateStart = new Date(e.target.value);
                  setProjectExperiences(oldData);
                }}
                defaultValue={generateDateString(new Date(experience.dateStart))}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                views={['year']}
                id={`projectExperienceEndDate${index}`}
                error={!!errors[`projectExperienceEndDate${index}`]}
                label="End Date"
                control={control}
                onType={(e) => {
                  const oldData = [...projectExperiences];
                  oldData[index].dateEnd = new Date(e.target.value);
                  setProjectExperiences(oldData);
                }}
                defaultValue={generateDateString(new Date(experience.dateEnd))}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextArea
                id={`projectExperienceDescription${index}`}
                error={!!errors[`projectExperienceDescription${index}`]}
                label="Description"
                control={control}
                defaultValue={experience.description}
                onType={(e) => {
                  const oldData = [...projectExperiences];
                  oldData[index].description = e.target.value;
                  setProjectExperiences(oldData);
                }}
                rows={5}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...projectExperiences];
            oldData.push({
              name: '',
              description: '',
              dateStart: new Date(),
              dateEnd: new Date(),
            });
            setProjectExperiences(oldData);
          }}
        >
          Add Project Experience
        </Button>
      </Grid2>
    </>
  );
};

export default ProjectExperience;
