import { makeAutoObservable, runInAction } from 'mobx';
import {
  getBoard,
  getBoardTasks,
  updateBoardTask,
  updateBoardColumn,
  createBoardColumn,
  deleteColumnFormBoard,
  deleteBoard,
} from '../api/boards.api';
import { Board, BoardTaskItem } from '../types/boards.types';
import { ModalTypes } from '../variables/modal.variables';
import { modalStore } from './modal.store';
import { updateOrdinal } from '../services/ordinal.service';

class BoardStore {
  private _isRefetching = false;

  private _loaded = false;

  private _board: Board | null = null;

  private _backlogBoardTasks: BoardTaskItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchBoard = async (boardId: string) => {
    this.loaded = false;
    const fetchedBoard = await getBoard(boardId);

    if (fetchedBoard) {
      runInAction(() => {
        this._board = fetchedBoard;
      });
    }

    this.loaded = true;
  };

  refetchBoard = async () => {
    if (!this._board) return;

    runInAction(() => {
      this._isRefetching = true;
    });

    const fetchedBoard = await getBoard(this._board.id);

    if (fetchedBoard) {
      runInAction(() => {
        this._board = fetchedBoard;
      });
    }

    runInAction(() => {
      this._isRefetching = false;
    });
  };

  fetchBacklogBoardTasks = async () => {
    if (this.board == null) return;
    const fetchedBacklogBoardTasks = await getBoardTasks({
      boardId: this.board.id,
      OnlyTasksNotAssignedToColumn: true,
    });
    if (fetchedBacklogBoardTasks) {
      runInAction(() => {
        this._backlogBoardTasks = fetchedBacklogBoardTasks;
      });
    }
  };

  clearBoard = () => {
    this._board = null;
    this._backlogBoardTasks = [];
    this._loaded = false;
  };

  updateBacklogBoardTaskOrdinal = async ({
    boardTaskId,
    ordinal,
  }: {
    boardTaskId: string;
    ordinal: number;
  }) => {
    if (!this.board) return;

    const currentBoardTask = this.getBacklogBoardTaskById(boardTaskId);
    if (!currentBoardTask) return;

    const currentOrdinal = currentBoardTask.ordinal;
    this._backlogBoardTasks = updateOrdinal({
      currentOrdinal,
      newOrdinal: ordinal,
      collection: this._backlogBoardTasks,
    });

    await updateBoardTask({
      boardId: this.board.id,
      boardTaskId,
      ordinal,
    });
  };

  updateBoardColumnOrdinal = async ({
    boardColumnId,
    ordinal,
  }: {
    boardColumnId: string;
    ordinal: number;
  }) => {
    if (!this._board) return;

    const currentBoardColumn = this.getBoardColumnById(boardColumnId);
    if (!currentBoardColumn) return;

    const currentOrdinal = currentBoardColumn.ordinal;
    this._board.boardColumns = updateOrdinal({
      currentOrdinal,
      newOrdinal: ordinal,
      collection: this._board.boardColumns,
    });

    await updateBoardColumn({
      boardId: this._board.id,
      boardColumnId,
      ordinal,
    });
  };

  deleteBoard = async () => {
    if (!this._board) return false;

    const deletedBoard = await deleteBoard(this._board.id);

    if (!deletedBoard) return false;

    return true;
  };

  createBoardColumn = async ({ name }: { name: string }) => {
    if (!this._board) return false;

    const createdBoardColumn = await createBoardColumn({ boardId: this._board.id, name });

    if (!createdBoardColumn) return false;

    this._board.boardColumns.push(createdBoardColumn);

    return true;
  };

  getBoardColumnById = (boardColumnId: string) => {
    return this.board?.boardColumns?.find((boardColumn) => boardColumn.id === boardColumnId);
  };

  getBacklogBoardTaskById = (boardTaskId: string) => {
    return this._backlogBoardTasks?.find((boardTask) => boardTask.id === boardTaskId);
  };

  removeColumnFromBoard = async (boardColumnId: string, boardId: string, name: string) => {
    const data = await deleteColumnFormBoard({
      boardId,
      boardColumnId,
    });
    modalStore.push({
      title: data ? 'Deleted' : 'Error',
      content: data
        ? `Column board "${name}" is deleted.`
        : 'Something went wrong. Try again later',
      type: ModalTypes.Info,
    });
    if (data && this._board?.boardColumns) {
      const index = this._board.boardColumns.findIndex((board) => board.id === boardColumnId);
      if (index > -1) {
        const newBoardColumns = [...this._board.boardColumns];
        newBoardColumns.splice(index, 1);
        this._board.boardColumns = newBoardColumns;
      }
    }
  };

  get boardColumnsSortedByOrdinal() {
    return this._board?.boardColumns?.slice().sort((a, b) => a.ordinal - b.ordinal);
  }

  get backlogBoardTasksSortedByOrdinal() {
    return this._backlogBoardTasks?.slice().sort((a, b) => a.ordinal - b.ordinal);
  }

  set loaded(loaded: boolean) {
    this._loaded = loaded;
  }

  get loaded() {
    return this._loaded;
  }

  get board() {
    return this._board;
  }

  get isRefetching() {
    return this._isRefetching;
  }
}

export const boardStore = new BoardStore();
