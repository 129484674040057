import { Backlog } from '../screens/BoardWorkspace/tabs/Backlog/backlog';
import { Board } from '../screens/BoardWorkspace/tabs/Board/board';
import { Settings } from '../screens/BoardWorkspace/tabs/Settings/settings';

export const boardTabs = [
  {
    url: '/boards/:boardId/backlog',
    path: 'backlog',
    title: 'Backlog',
    component: <Backlog />,
  },
  {
    url: '/boards/:boardId',
    path: '',
    title: 'Board',
    component: <Board />,
  },
  {
    url: '/boards/:boardId/settings',
    path: 'settings',
    title: 'Settings',
    component: <Settings />,
  },
];

export const BOARD_COLUMN_WIDTH = 250;
export const BOARD_COLUMN_CREATE_WIDTH = 64;
