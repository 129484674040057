import { FunctionComponent, useEffect } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, SelectChangeEvent, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Control, FieldErrorsImpl } from 'react-hook-form';
import Select from '../../components/FormFields/select';
import { IPersonDetailed } from '../../types/candidate.types';
import { ISelectedLanguages } from '../../types/language.types';
import { ISelectOption } from '../../types/form.types';

interface ILanguageProps {
  control: Control;
  errors: FieldErrorsImpl;
  selectedLanguages: ISelectedLanguages[];
  languages: ISelectOption[];
  languageLevels: ISelectOption[];
  setSelectedLanguages: (selectedLnaguages: ISelectedLanguages[]) => void;
  candidate: IPersonDetailed | null;
  id: string | undefined;
}

const Languages: FunctionComponent<ILanguageProps> = ({
  control,
  errors,
  selectedLanguages,
  languages,
  languageLevels,
  setSelectedLanguages,
  candidate,
  id,
}) => {
  useEffect(() => {
    if (id) {
      const result = candidate?.languages.map((language) => {
        const levelId = languageLevels.find((level) => level.label === language.level);
        return { languageId: language.id, languageLevelId: levelId?.value as string };
      });
      setSelectedLanguages(result || []);
    }
  }, []);

  return (
    <>
      <Grid2 xs={12}>
        <Typography variant="h4">Languages</Typography>
      </Grid2>
      {selectedLanguages.map((selectedLnaguage, index) => {
        return (
          <Grid2 xs={12} container key={`${selectedLnaguage.languageId}${index.toString()}`}>
            <Grid2 xs={6}>
              <Select
                id={`languageId${index}`}
                registerOptions={{ required: true }}
                control={control}
                label="Language"
                multiple={false}
                error={!!errors[`languageId${index}`]}
                options={languages}
                labelId={`languageLabelId${index}`}
                onSelect={(e: SelectChangeEvent<string | string[] | Date | number>) => {
                  const oldData = [...selectedLanguages];
                  oldData[index].languageId = e.target.value as string;
                  setSelectedLanguages(oldData);
                }}
                defaultValue={selectedLnaguage.languageId}
              />
            </Grid2>
            <Grid2 xs={6}>
              <Select
                id={`languageLevelId${index}`}
                registerOptions={{ required: true }}
                control={control}
                label="Language Level"
                multiple={false}
                error={!!errors[`languageLevelId${index}`]}
                options={languageLevels}
                labelId={`languageLevelLabelId${index}`}
                onSelect={(e: SelectChangeEvent<string | string[] | Date | number>) => {
                  const oldData = [...selectedLanguages];
                  oldData[index].languageLevelId = e.target.value as string;
                  setSelectedLanguages(oldData);
                }}
                defaultValue={selectedLnaguage.languageLevelId}
              />
            </Grid2>
          </Grid2>
        );
      })}
      <Grid2 xs={12}>
        <Button
          type="button"
          variant="text"
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            const oldData = [...selectedLanguages];
            oldData.push({
              languageId: '',
              languageLevelId: '',
            });
            setSelectedLanguages(oldData);
          }}
        >
          Add Language
        </Button>
      </Grid2>
    </>
  );
};

export default Languages;
