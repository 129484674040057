export interface ParsedPaginationHeader {
  TotalCount: number;
  PageSize: number;
  CurrentPage: number;
}

export const parsePaginationHeader = (paginationValue?: string): ParsedPaginationHeader | false => {
  try {
    if (!paginationValue) return false;

    return JSON.parse(paginationValue) as ParsedPaginationHeader;
  } catch {
    return false;
  }
};
