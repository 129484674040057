import { Card, Grid, CardContent, CardActions, Skeleton } from '@mui/material';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';

export const BoardsSkeleton = () => {
  const rows = Array(4).fill(0);

  const boardsSkeletonCards = rows.map(() => (
    <Grid key={Math.random()} xs={12} sm={6} lg={3} item>
      <Card>
        <CardContent sx={{ paddingBottom: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item width="70%" minWidth={150}>
              <Skeleton variant="text" height={24} />
            </Grid>
            <Grid item>
              <WorkspacesOutlinedIcon />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ paddingX: 2 }}>
          <Grid container spacing={1}>
            <Grid item width={96}>
              <Skeleton height={32} />
            </Grid>
            <Grid item width={96}>
              <Skeleton height={32} />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  ));

  return (
    <Grid container direction="row" flexGrow="wrap" spacing={2}>
      {boardsSkeletonCards}
    </Grid>
  );
};
