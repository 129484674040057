import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, CardContent, CardActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { BDBButton } from '../../../layouts/BDB-Button/bdb-button';
import { useCreateBoardModal } from '../../../hooks/modals/useCreateBoardModal';
import { BoardsSkeleton } from './boards-skeleton';
import { modalStore } from '../../../store/modal.store';
import { BoardCreate } from './board-create';
import { boardsStore } from '../../../store/boards.store';

const Boards = () => {
  const { boards, loaded: isLoadedBoards } = boardsStore;
  const { onCreateBoard } = useCreateBoardModal({ modalStore });
  const navigate = useNavigate();

  useEffect(() => {
    boardsStore.fetchBoards();
  }, []);

  const renderedBoards = boards.map((board) => (
    <Grid key={board.id} xs={12} sm={6} md={4} xl={3} item>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent sx={{ paddingBottom: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center" flexWrap="nowrap">
            <Grid item>
              <Typography fontSize={16}>{board.name}</Typography>
            </Grid>
            <Grid item>
              <WorkspacesOutlinedIcon />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container>
            <Grid item>
              <BDBButton
                variant="text"
                type="button"
                fontSize="14px"
                sx={{ lineHeight: '20px' }}
                onClick={() => navigate(`/boards/${board.id}`)}
              >
                Open
              </BDBButton>
            </Grid>
            <Grid item>
              <BDBButton
                variant="text"
                type="button"
                fontSize="14px"
                sx={{ lineHeight: '20px' }}
                onClick={() => navigate(`/boards/${board.id}/settings`)}
              >
                Manage
              </BDBButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  ));

  if (!isLoadedBoards) return <BoardsSkeleton />;

  return (
    <Grid container direction="row" flexGrow="wrap" spacing={2}>
      <BoardCreate onClick={() => onCreateBoard()} />
      {renderedBoards}
    </Grid>
  );
};

export default observer(Boards);
