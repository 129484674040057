import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Button, Box, ListItemIcon, ListItemText } from '@mui/material';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import userStore from '../../store/user.store';
import { colorThemes } from '../../variables/style.variables';

export const UserMenu = () => {
  const { logout } = userStore;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const openUserMenu = Boolean(anchorEl);
  const navigation = useNavigate();

  const handleClickUserMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    navigation('/login');
    logout();
    setAnchorEl(null);
  };

  return (
    <Box sx={{ right: 0, textAlign: 'center', justifySelf: 'flex-end' }}>
      <Button
        id="basic-button"
        aria-controls={openUserMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openUserMenu ? 'true' : undefined}
        onClick={handleClickUserMenu}
        sx={{
          color: colorThemes.black,
          fontWeight: 500,
          fontSize: 16,
          textTransform: 'none',
          '&:hover': {
            backgroundColor: colorThemes.grey4,
          },
        }}
      >
        {userStore.userFullName}
        <KeyboardArrowDownOutlinedIcon
          sx={{
            transform: `rotate(${openUserMenu ? '180' : '0'}deg)`,
            transition: 'transform .15s linear',
            color: colorThemes.primary,
          }}
        />
      </Button>
      <Menu
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: -20, horizontal: 0 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={openUserMenu}
        onClose={handleCloseUserMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiMenu-list': {
            padding: '4px 0',
            minWidth: '150px',
          },
        }}
      >
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <ExitToAppOutlinedIcon style={{ color: colorThemes.primary }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
};
