import { authRequest } from '../services/api.services';
import { BlindApiFileFormats } from '../variables/blinds-api.variables';

interface GetBlindArgs {
  personId: string;
  fileFormat: BlindApiFileFormats;
}

export const getBlind = async ({ personId, fileFormat }: GetBlindArgs): Promise<string | false> => {
  try {
    const response = await authRequest<string>({
      url: `/blinds/person/${personId}`,
      method: 'post',
      data: { fileFormat },
      responseType: 'blob',
    });

    return response?.data;
  } catch {
    return false;
  }
};
