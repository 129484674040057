import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { DialogContent, DialogActions, Grid } from '@mui/material';
import Input from '../../components/FormFields/input';
import { FormErrors } from '../../components/FormErrors/form-errors';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { BaseUseModalArgs } from '../../types/modal.types';
import { ModalTypes } from '../../variables/modal.variables';
import { ModalContentElementProps } from '../../components/ModalProvider/modal-provider';
import { boardStore } from '../../store/board.store';

interface OnCreateBoardColumnArgs {
  isFirstBoardColumn?: boolean;
}

const CreateBoardColumnForm = observer(({ closeModal = () => {} }: ModalContentElementProps) => {
  const [isProcessingCreateColumn, setIsProcessingCreateColumn] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onCreateBoardColumnSubmit = async ({ name }: Record<string, string>) => {
    setIsProcessingCreateColumn(true);
    await boardStore.createBoardColumn({ name });
    setIsProcessingCreateColumn(false);

    closeModal();
  };

  return (
    <>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onCreateBoardColumnSubmit)} id="CreateBoardColumnForm">
          <Input
            type="text"
            control={control}
            label="Name"
            id="name"
            registerOptions={{
              required: 'Board column name is required',
              minLength: {
                value: 2,
                message: 'Board column name has to contain at least 2 characters',
              },
              maxLength: {
                value: 40,
                message: 'Board column name may contain max 40 characters',
              },
            }}
          />
          {errors?.name && <FormErrors messages={[String(errors.name.message)]} />}
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <BDBButton
              type="submit"
              variant="text"
              form="CreateBoardColumnForm"
              loading={isProcessingCreateColumn}
            >
              Create
            </BDBButton>
          </Grid>
          <Grid item>
            <BDBButton variant="text" onClick={closeModal} disabled={isProcessingCreateColumn}>
              Cancel
            </BDBButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
});

export const useCreateBoardColumnModal = ({ modalStore }: BaseUseModalArgs) => {
  const onCreateBoardColumn = ({ isFirstBoardColumn }: OnCreateBoardColumnArgs) => {
    const mainTitle = isFirstBoardColumn
      ? 'Create your first column in board'
      : 'Add new column to your board';

    modalStore.push({
      title: mainTitle,
      maxWidth: 'xs',
      content: <CreateBoardColumnForm />,
      type: ModalTypes.CustomContent,
    });
  };

  return { onCreateBoardColumn };
};
