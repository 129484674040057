import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Skeleton } from '@mui/material';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { boardStore } from '../../store/board.store';
import { BoardWorkspaceTabs } from './components/board-workspace-tabs';
import { BDBCircularProgress } from '../../layouts/BDB-CircularProgress/bdb-circular-progress';
import { useRouteMatch } from '../../hooks/router/useRouteMatch';
import { boardTabs } from '../../variables/board-workspace.variables';

export const BoardWorkspace = observer(() => {
  const { boardId } = useParams();
  const routeMatch = useRouteMatch(boardTabs.map((tab) => tab.url));
  const currentTab = routeMatch?.pattern?.path;
  const { board, loaded: isBoardLoaded, isRefetching } = boardStore;
  const mainTitle = isBoardLoaded ? board?.name : <Skeleton height={28} width={180} />;

  useEffect(() => {
    boardStore.refetchBoard();
  }, [currentTab]);

  useEffect(() => {
    if (boardId) {
      boardStore.fetchBoard(boardId);
    }

    return () => {
      boardStore.clearBoard();
    };
  }, [boardId]);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{
        mainTitle,
        subTitle: 'Contribute to your workspaces',
      }}
      style={{ pointerEvents: isRefetching ? 'none' : 'auto' }}
    >
      {isBoardLoaded ? (
        <>
          <BoardWorkspaceTabs currentTab={currentTab} />
          <Outlet />
        </>
      ) : (
        <BDBCircularProgress />
      )}
    </BDBContainer>
  );
});
