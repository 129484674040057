import { authRequest } from '../services/api.services';
import { PostEmployeeProps } from '../types/add-employee.types';
import { IPersonDetailed } from '../types/candidate.types';

export const addEmployee = async (
  id: string,
  payload: PostEmployeeProps,
): Promise<IPersonDetailed | false> => {
  try {
    const response = await authRequest({
      url: `/persons/${id}/employee`,
      method: 'post',
      data: payload,
    });
    return response?.data;
  } catch {
    return false;
  }
};
