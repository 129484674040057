import React, { useState } from 'react';
import { Button, Grid, ButtonProps, Typography } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { colorThemes } from '../../variables/style.variables';

interface PostData {
  title: string;
  body: string;
  image: File | null;
}

interface IUploadButtonProps extends ButtonProps {
  onDelete?: () => void;
  name: string;
}

export const UploadButton = ({ name, onDelete, ...props }: IUploadButtonProps) => {
  const initialValue = {
    title: '',
    body: '',
    image: null,
  };
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<PostData>(initialValue);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploaded(true);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      image: event.target.files ? event.target.files[0] : null,
    }));
  };

  const handleImageDelete = () => {
    setFormValues(initialValue);
    setUploaded(false);
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <Grid container item alignItems="center">
      <Grid item sx={{ marginRight: '20px' }} justifyContent="center">
        <Typography variant="h6">{name} </Typography>
      </Grid>
      <Grid item>
        <BDBButton {...props} component="label">
          {formValues.image?.name ?? `Send`}
          <input onChange={handleImageChange} type="file" hidden />
        </BDBButton>
      </Grid>
      <Grid item>
        {uploaded && (
          <Button onClick={handleImageDelete}>
            <HighlightOff sx={{ color: colorThemes.primary }} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
