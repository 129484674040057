import { DownloadFileTypes } from '../variables/download.variables';

interface DownloadFileArgs {
  content: string;
  name: string;
  type: DownloadFileTypes;
}

export const downloadFile = ({ content, name, type }: DownloadFileArgs) => {
  const linkElement = document.createElement('a');
  const fileBlob = new Blob([content], { type });

  linkElement.href = window.URL.createObjectURL(fileBlob);
  linkElement.download = name;
  document.body.append(linkElement);
  linkElement.click();
  linkElement.remove();

  setTimeout(() => URL.revokeObjectURL(linkElement.href), 100);
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
