import { TableHead, TableRow, TableCell } from '@mui/material';

interface ApplicationTableHeadCell {
  id: string;
  label: string;
  minWidth: string;
  width?: string;
}

const applicationTableHeadCells: readonly ApplicationTableHeadCell[] = [
  {
    id: 'name',
    label: 'Name',
    minWidth: '200px',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: '200px',
  },
  {
    id: 'availability',
    label: 'Availability',
    minWidth: '130px',
  },
  {
    id: 'financialExpectations',
    label: 'Financial expectations',
    minWidth: '130px',
  },
];

export function ApplicationTableHead() {
  return (
    <TableHead>
      <TableRow>
        {applicationTableHeadCells.map((applicationTableHeadCell) => (
          <TableCell
            key={applicationTableHeadCell.id}
            sx={{
              minWidth: applicationTableHeadCell.minWidth,
              width: applicationTableHeadCell.width || 'auto',
            }}
          >
            {applicationTableHeadCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
