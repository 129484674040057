import { makeAutoObservable } from 'mobx';
import jwt from 'jwt-decode';
import { IDecodedToken, IUser, Roles } from '../types/user.types';
import { SignInRequest } from '../types/api-identity.types';
import { getUser, signIn } from '../api/identity.api';
import { LocalStorageKeys } from '../variables/local-storage.variables';

const defaultUserObject: IUser = {
  appuserId: '',
  userId: '',
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  roles: [{ id: 'e427a3cf-e750-4290-82c4-1756d4e1ed8c', name: Roles.GUEST }],
};

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  user: IUser = defaultUserObject;

  isLoadingSession = true;

  async getUserData() {
    const token = localStorage.getItem(LocalStorageKeys.AuthorizationToken) as string;

    if (!token) return;

    const decodedToken: IDecodedToken = jwt(token);
    const user = await getUser(decodedToken.user_id);

    if (!user) return;

    this.setUser(user);
  }

  async signIn(signInData: SignInRequest): Promise<boolean> {
    const jwtToken = await signIn(signInData);

    if (jwtToken) {
      localStorage.setItem(LocalStorageKeys.AuthorizationToken, jwtToken);

      await this.getUserData();

      return true;
    }

    return false;
  }

  setIsLoadingSession = (isLoadingSession: boolean) => {
    this.isLoadingSession = isLoadingSession;
  };

  setUser = (user: IUser) => {
    this.user = user;
  };

  logout = () => {
    this.user = defaultUserObject;
    localStorage.removeItem(LocalStorageKeys.AuthorizationToken);
  };

  hasUserRole(roleName: string) {
    return !!this.user?.roles?.find((role) => role.name === roleName);
  }

  hasUserAtLeastOneRole(roles: string[]) {
    return this.user?.roles?.some((userRole) => roles.includes(userRole.name));
  }

  get isLoggedIn() {
    return this.hasUserRole(Roles.ADMIN) || this.hasUserRole(Roles.HR);
  }

  get rolesInfo() {
    const isHR = this.hasUserRole(Roles.HR);
    const isAdmin = this.hasUserRole(Roles.ADMIN);
    const isGuest = this.hasUserRole(Roles.GUEST);

    return {
      isHR,
      isAdmin,
      isGuest,
    };
  }

  get userFullName() {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }
}

const userStore = new UserStore();

export default userStore;
