export const colorThemes = {
  black: '#000000',
  white: '#ffffff',
  primary: '#FD0399',
  primaryHover: '#df0287',
  grey4: 'rgba(0, 0, 0, 0.04)',
  grey10: '#efefef',
  grey50: '#adadad',
  grey: '#79747E',
  redWarning: '#de0408',
  lightBlueBackground: '#f7f8fd',
  black_08: 'rgba(0, 0, 0, 0.08)',
};
