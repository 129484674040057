import { TableBody, TableCell, TableRow, Skeleton, Grid, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CANDIDATES_PAGE_SIZE } from '../../../variables/config';

export const CandidatesTableSkeleton = () => {
  const rows = Array(CANDIDATES_PAGE_SIZE).fill(0);

  const candidatesTableSkeletonRows = rows.map(() => (
    <TableRow key={Math.random()}>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <Grid container direction="row" columnSpacing={1}>
          <Grid item xs>
            <Skeleton variant="text" height={36} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="text" height={36} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Grid container direction="row" columnSpacing={1}>
          <Grid item xs>
            <Skeleton variant="text" height={36} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="text" height={36} />
          </Grid>
          <Grid item xs>
            <Skeleton variant="text" height={36} />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Skeleton variant="text" height={20} />
      </TableCell>
      <TableCell>
        <IconButton disabled>
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ));

  return <TableBody>{candidatesTableSkeletonRows}</TableBody>;
};
