import { FunctionComponent } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router';
import { boardStore } from '../../../../../store/board.store';
import { modalStore } from '../../../../../store/modal.store';
import { ModalTypes } from '../../../../../variables/modal.variables';

interface IBoardColumnMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: (value: null | HTMLElement) => void;
  boardColumnId: string;
  name: string;
}

const BoardColumnMenu: FunctionComponent<IBoardColumnMenuProps> = ({
  anchorEl,
  setAnchorEl,
  boardColumnId,
  name,
}) => {
  const isMenuOpen = Boolean(anchorEl);
  const { boardId } = useParams();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleCloseMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 0, horizontal: 'right' }}
    >
      <MenuItem
        onClick={() => {
          const canDelete = boardStore.board && boardStore.board.boardColumns.length > 1;
          modalStore.push({
            title: canDelete ? 'Are you sure?' : 'Sorry.',
            content: canDelete
              ? `you are deleting board column "${name}".`
              : "you can't delete last column of the board.",
            type: canDelete ? ModalTypes.Confirmation : ModalTypes.Info,
            onAgree: async () => {
              boardStore.removeColumnFromBoard(boardColumnId, boardId as string, name);
            },
          });
        }}
      >
        <ListItemIcon>
          <DeleteIcon sx={{ marginRight: '4px' }} />
        </ListItemIcon>
        <ListItemText primary="Delete" />
      </MenuItem>
    </Menu>
  );
};

export default BoardColumnMenu;
