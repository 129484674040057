import { Card, Grid } from '@mui/material';
import { forwardRef } from 'react';

interface BoardColumnTasksProps {
  boardColumnTasksHeight: number;
}

export const BoardColumnTasks = forwardRef<HTMLDivElement, BoardColumnTasksProps>(
  ({ boardColumnTasksHeight }, ref) => {
    const arr = new Array(0);
    return (
      <Grid
        pb="2px"
        style={{ overflowY: 'auto' }}
        px={1}
        mt="3px"
        mb={1}
        height={boardColumnTasksHeight}
        maxHeight={boardColumnTasksHeight}
        ref={ref}
      >
        <Grid container direction="column" rowSpacing={1}>
          {arr.map(() => (
            <Grid item key={Math.random()}>
              <Card sx={{ height: 100 }}>Tasks not implemented</Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  },
);
