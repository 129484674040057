import {
  ICertificate,
  IEducations,
  IProjectExperience,
  IWorkExperiences,
} from '../types/candidate.types';
import { ISelectedLanguages } from '../types/language.types';
import { ISelectedSkills } from '../types/skill.types';
import { ITableColumn } from '../types/table.types';

export const CandidateListTableColumns: ITableColumn[] = [
  {
    field: 'firstName',
    headerName: 'First Name',
    width: 120,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    width: 120,
  },
  {
    field: 'email',
    headerName: 'E-mail',
    width: 250,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 170,
  },
  {
    field: 'gdprDate',
    headerName: 'Rodo',
    width: 105,
  },
  {
    field: 'currentJobPosition',
    headerName: 'Current job position',
    width: 200,
  },
  {
    field: 'projects',
    headerName: 'Projects',
    width: 230,
  },
];

export const DefaultSelectedSkillsObj: ISelectedSkills = { skillGroupIndex: 0, skillId: [] };

export const DefaultSelectedLanguageObj: ISelectedLanguages = {
  languageId: '',
  languageLevelId: '',
};

export const DefaultWorkExperienceObj: IWorkExperiences = {
  jobPosition: '',
  company: '',
  startDate: new Date(),
  endDate: new Date(),
  description: '',
  responsibilities: '',
  technologies: [],
};

export const DefaultEducationObj: IEducations = {
  institution: '',
  scientificTitle: '',
  startDate: new Date(),
  endDate: new Date(),
  location: '',
};

export const DefaultCertificateObj: ICertificate = {
  name: '',
  description: '',
};

export const DefaultProjectExperienceObj: IProjectExperience = {
  name: '',
  description: '',
  dateStart: new Date(),
  dateEnd: new Date(),
};
