import Radio, { RadioProps } from '@mui/material/Radio';
import { colorThemes } from '../../variables/style.variables';

interface BDBRadioProps extends RadioProps {
  customColor?: string;
}

export const BDBRadio = ({ customColor = colorThemes.primary, ...props }: BDBRadioProps) => {
  return (
    <Radio
      {...props}
      sx={{
        '&.Mui-checked': {
          color: customColor,
        },
      }}
    />
  );
};
