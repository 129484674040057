import { FunctionComponent, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';
import { IFormControl, ISelectOption } from '../../types/form.types';
import { colorThemes } from '../../variables/style.variables';

interface ISelectProps extends IFormControl {
  multiple?: boolean;
  options: ISelectOption[];
  labelId: string;
  onSelect?: (e: SelectChangeEvent<string | string[] | Date | number>) => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Select: FunctionComponent<ISelectProps> = ({
  label,
  defaultValue = '',
  id,
  control,
  registerOptions,
  error,
  multiple,
  options,
  labelId,
  onSelect,
}) => {
  const [selectedValues, setSelectedValue] = useState<string | string[] | Date | number>(
    multiple && !defaultValue ? [] : defaultValue,
  );

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      render={({ field: { onChange, onBlur } }) => (
        <FormControl
          sx={{
            width: '100%',
            '& label.Mui-focused': {
              color: colorThemes.primary,
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: colorThemes.primary,
              },
            },
          }}
        >
          <InputLabel id={labelId}>{label}</InputLabel>
          <MuiSelect
            labelId={labelId}
            onChange={(event) => {
              if (onSelect) {
                onSelect(event);
              }
              onChange(event);
              handleChange(event);
            }}
            onBlur={onBlur}
            label={label}
            error={error}
            multiple={multiple}
            value={selectedValues}
            MenuProps={MenuProps}
          >
            {options.map((option, index) => (
              <MenuItem
                key={`${option.value}${index.toString()}`}
                value={option.value}
                selected={
                  (Array.isArray(selectedValues) &&
                    selectedValues.includes(option.value as string)) ||
                  option.value === selectedValues
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      )}
    />
  );
};

export default Select;
