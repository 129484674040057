import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Tabs, Tab, Box } from '@mui/material';
import { colorThemes } from '../../../variables/style.variables';
import { boardTabs } from '../../../variables/board-workspace.variables';

interface BoardWorkspaceTabsProps {
  currentTab?: string;
}

export const BoardWorkspaceTabs = observer(({ currentTab }: BoardWorkspaceTabsProps) => {
  return (
    <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={currentTab}
        aria-label="board tabs"
        TabIndicatorProps={{
          style: {
            background: colorThemes.primary,
          },
        }}
      >
        {boardTabs.map((tab) => (
          <Tab
            key={tab.url}
            label={tab.title}
            value={tab.url}
            component={Link}
            to={tab.path}
            sx={{ textTransform: 'none', '&.Mui-selected': { color: colorThemes.primary } }}
          />
        ))}
      </Tabs>
    </Box>
  );
});
