import { Grid, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormErrors } from '../../../components/FormErrors/form-errors';
import { BDBRadio } from '../../../layouts/BDB-Radio/bdb-radio';
import { BDBTypography } from '../../../layouts/BDB-Typography/bdb-typography';
import { BDBGrid } from '../../../layouts/BDB-Grid/bdb-grid';
import { DatePicker } from '../../../components/FormFields/date-picker';

export const Agreements = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const {
    expiryDate: expiryDateError,
    type: typeError,
    stage: stageError,
    contractExtended: contractExtendedError,
    nda: ndaError,
  } = errors;

  return (
    <BDBGrid>
      <Grid item xs={12}>
        <BDBTypography variant="h5">Agreements </BDBTypography>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          id="expiryDate"
          label="End of agreement"
          control={control}
          error={!!expiryDateError}
          registerOptions={{ required: true }}
        />
        {expiryDateError?.message && <FormErrors messages={[String(expiryDateError?.message)]} />}
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Type of contract *
          </FormLabel>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="Mandate contract" control={<BDBRadio />} label="Mandate" />
                <FormControlLabel
                  value="Employment contract"
                  control={<BDBRadio />}
                  label="Employment"
                />
                <FormControlLabel value="B2B" control={<BDBRadio />} label="B2B" />
              </RadioGroup>
            )}
          />
          {typeError?.message && <FormErrors messages={[String(typeError?.message)]} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Agreement stage *
          </FormLabel>
          <Controller
            name="stage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="In progress" control={<BDBRadio />} label="In progress" />
                <FormControlLabel value="Done" control={<BDBRadio />} label="Done" />
                <FormControlLabel value="Signed" control={<BDBRadio />} label="Signed" />
              </RadioGroup>
            )}
          />
          {stageError?.message && <FormErrors messages={[String(stageError?.message)]} />}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Contract extended *
          </FormLabel>
          <Controller
            name="contractExtended"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value control={<BDBRadio />} label="Yes" />
                <FormControlLabel value={false} control={<BDBRadio />} label="No" />
              </RadioGroup>
            )}
          />
          {contractExtendedError?.message && (
            <FormErrors messages={[String(contractExtendedError?.message)]} />
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <FormLabel
            sx={{
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            Non-disclosure agreement *
          </FormLabel>
          <Controller
            name="nda"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup row value={value} onChange={onChange}>
                <FormControlLabel value="Yes" control={<BDBRadio />} label="Yes" />
                <FormControlLabel value="No" control={<BDBRadio />} label="No" />
                <FormControlLabel
                  value="Not required"
                  control={<BDBRadio />}
                  label="Not required"
                />
              </RadioGroup>
            )}
          />
          {ndaError?.message && <FormErrors messages={[String(ndaError?.message)]} />}
        </FormControl>
      </Grid>
    </BDBGrid>
  );
};
