import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { TableRow, TableCell, IconButton, Box } from '@mui/material';
import ReorderIcon from '@mui/icons-material/Reorder';
import { BoardTaskItem } from '../../../../../types/boards.types';
import { colorThemes } from '../../../../../variables/style.variables';

interface BacklogBoardTaskRowProps {
  boardTask: BoardTaskItem;
}

export const BacklogBoardTaskRow = ({ boardTask }: BacklogBoardTaskRowProps) => {
  return (
    <Draggable key={boardTask.id} draggableId={boardTask.id} index={boardTask.ordinal}>
      {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <TableRow
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          style={draggableProvided.draggableProps.style}
          sx={{ backgroundColor: colorThemes.white, boxShadow: snapshot.isDragging ? 2 : 0 }}
        >
          <TableCell align="left">
            <Box {...draggableProvided.dragHandleProps}>
              <IconButton size="small">
                <ReorderIcon />
              </IconButton>
            </Box>
          </TableCell>
          <TableCell align="left" width="100%">
            {boardTask.name}
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};
