import { Grid, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { BDBButton } from '../../../layouts/BDB-Button/bdb-button';
import { IEmployee } from '../../../types/employee.types';

interface EmployeesTableBodyProps {
  employees: IEmployee[];
}

export const EmployeesTableBody = ({ employees }: EmployeesTableBodyProps) => {
  const navigate = useNavigate();

  const getFullName = (employee: IEmployee) => `${employee.firstName} ${employee.lastName}`;

  const employeesTableBodyRows = employees.map((employee) => {
    const employeeFullName = getFullName(employee);

    return (
      <TableRow key={employee.id} sx={{ height: 'auto' }}>
        <TableCell>{employeeFullName}</TableCell>
        <TableCell>{employee.email}</TableCell>
        <TableCell>{employee.phoneNumber}</TableCell>
        <TableCell>
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <BDBButton
                variant="text"
                fontWeight={400}
                onClick={() => navigate(`/employee/${employee.id}`)}
                fontSize="14px"
              >
                <EditIcon sx={{ marginRight: '4px' }} />
                Edit
              </BDBButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  });

  return <TableBody>{employeesTableBodyRows}</TableBody>;
};
