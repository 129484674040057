import { PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react';
import userStore from '../../store/user.store';

interface SessionProviderProps extends PropsWithChildren {}

export const SessionProvider = observer(({ children }: SessionProviderProps) => {
  const handleSession = async () => {
    userStore.setIsLoadingSession(true);

    await userStore.getUserData();

    userStore.setIsLoadingSession(false);
  };

  useEffect(() => {
    handleSession();
  }, []);

  return children as JSX.Element;
});
