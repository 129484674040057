import { BDBContainer } from '../../../../layouts/BDB-Container/bdb-container';
import { GeneralSetting } from './components/general-settings';
import { DeleteBoardButton } from './components/delete-board-button';

export const Settings = () => {
  return (
    <BDBContainer maxWidth="xs">
      <GeneralSetting />
      <DeleteBoardButton />
    </BDBContainer>
  );
};
