import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react';
import AddCandidate from '../screens/AddCandidate/add-candidate';
import AddEmployee from '../screens/AddEmployee/add-employee';
import AdminUsers from '../screens/AdminUsers/admin-users';
import Dashboard from '../screens/Dashboard/dashboard';
import Login from '../screens/Login/login';
import ProtectedRoute from './protected-route.tsx';
import Error404 from '../components/Error/error-404';
import { Roles } from '../types/user.types';
import EditCandidate from '../screens/EditCandidate/editCandidate';
import { Apply } from '../screens/Apply/apply';
import Applications from '../screens/Applications/applications';
import Employees from '../screens/Employees/employees';
import Candidates from '../screens/Candidates/candidates';
import Projects from '../screens/Projects/projects';
import { BoardWorkspace } from '../screens/BoardWorkspace/board-workspace';
import { boardTabs } from '../variables/board-workspace.variables';

const Routing = () => {
  return (
    <Routes>
      <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.HR]} />}>
        <Route path="/candidates/create" element={<AddCandidate />} />
        <Route path="/candidates/edit/:id" element={<EditCandidate />} />
        <Route path="/persons/:id/employee" element={<AddEmployee />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/candidates" element={<Candidates />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/boards/:boardId" element={<BoardWorkspace />}>
          {boardTabs.map((boardTab) => (
            <Route key={boardTab.url} path={boardTab.path} element={boardTab.component} />
          ))}
        </Route>
      </Route>
      <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
        <Route path="/admin/users" element={<AdminUsers />} />
      </Route>
      <Route path="/apply/:projectId" element={<Apply />} />
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default observer(Routing);
