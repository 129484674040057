import { Typography } from '@mui/material';
import { colorThemes } from '../../variables/style.variables';

interface FormErrorsProps {
  messages: string[];
}

export const FormErrors = ({ messages }: FormErrorsProps) => {
  return (
    <>
      {messages.map((message: string) => (
        <Typography key={message} color={colorThemes.redWarning} fontSize="14px" mt="4px">
          {message}
        </Typography>
      ))}
    </>
  );
};
