import { useState, useEffect, ChangeEvent } from 'react';
import { Pagination, Grid } from '@mui/material';
import _ from 'lodash';
import { Person } from '../../types/person.types';
import { getProjects } from '../../api/project.api';
import { getPersons } from '../../api/persons.api';
import { getUsers } from '../../api/users.api';
import { getSkills } from '../../api/skills.api';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { CandidatesTable } from './components/candidates-table';
import { CANDIDATES_PAGE_SIZE } from '../../variables/config';
import { SelectFilterOption } from '../../layouts/SelectFilter/select-filter';
import { roleStore } from '../../store/role.store';
import { Roles } from '../../types/user.types';

export interface CandidatesFilters {
  projects: {
    value: string[];
    options: SelectFilterOption[];
  };
  recruiters: {
    value: string[];
    options: SelectFilterOption[];
  };
  skills: {
    value: string[];
    options: SelectFilterOption[];
  };
}

const Candidates = () => {
  const [filters, setFilters] = useState<CandidatesFilters>({
    projects: {
      value: [],
      options: [],
    },
    recruiters: {
      value: [],
      options: [],
    },
    skills: {
      value: [],
      options: [],
    },
  });
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(true);
  const [candidates, setCandidates] = useState<Person[]>([]);
  const [candidatesPage, setCandidatesPage] = useState(1);
  const [candidatesTotalCount, setCandidatesTotalCount] = useState(-1);
  const pagesCount = candidatesTotalCount / CANDIDATES_PAGE_SIZE;
  const isManyPages = pagesCount > 1;

  const fetchCandidates = async () => {
    setIsLoadingCandidates(true);

    const getCandidatesResult = await getPersons({
      type: 'Candidate',
      pageNumber: candidatesPage,
      pageSize: CANDIDATES_PAGE_SIZE,
      projectIds: filters.projects.value,
      recruiterIds: filters.recruiters.value,
      skillIds: filters.skills.value,
    });

    setCandidates(getCandidatesResult.persons);

    if (getCandidatesResult?.pagination) {
      setCandidatesTotalCount(getCandidatesResult?.pagination?.TotalCount);
    } else {
      setCandidatesTotalCount(-1);
    }

    setIsLoadingCandidates(false);
  };

  const fetchProjects = async () => {
    const fetchedProjects = await getProjects();

    setFilters((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      updatedFilters.projects.options = fetchedProjects.map((fetchedProject) => ({
        value: fetchedProject.id,
        label: fetchedProject.name,
      }));
      return updatedFilters;
    });
  };

  const fetchRecruiters = async () => {
    await roleStore.fetchRoles();
    const hrRole = roleStore.getRoleByName(Roles.HR);

    if (!hrRole) return;

    const fetchedRecruiters = await getUsers({
      roles: [hrRole.id],
    });

    setFilters((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      updatedFilters.recruiters.options = fetchedRecruiters.map((fetchedRecruiter) => ({
        value: fetchedRecruiter.userId,
        label: `${fetchedRecruiter.firstName} ${fetchedRecruiter.lastName}`,
      }));
      return updatedFilters;
    });
  };

  const fetchSkillGroups = async () => {
    const fetchedSkillGroups = await getSkills();
    const skills = fetchedSkillGroups.map((fetchedSkillGroup) => fetchedSkillGroup.skills);
    const skillsFlat = skills.flat();

    setFilters((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      updatedFilters.skills.options = skillsFlat.map((skillFlat) => ({
        value: skillFlat.id,
        label: skillFlat.name,
      }));
      return updatedFilters;
    });
  };

  const handleChangePage = (e: ChangeEvent<unknown>, newPage: number) => {
    setCandidatesPage(newPage);
  };

  const handleChangeFilters = (value: string[], filter: keyof CandidatesFilters) => {
    setFilters((prevFilters) => {
      const updatedFilters = _.cloneDeep(prevFilters);
      updatedFilters[filter].value = value;
      return updatedFilters;
    });
  };

  const handleCloseFilter = (isChanged: boolean, filter: keyof CandidatesFilters) => {
    if (!isChanged) return;

    switch (filter) {
      case 'projects':
      case 'recruiters':
      case 'skills':
        if (candidatesPage === 1) fetchCandidates();
        else setCandidatesPage(1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchCandidates();
  }, [candidatesPage]);

  useEffect(() => {
    fetchProjects();
    fetchRecruiters();
    fetchSkillGroups();
  }, []);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{ mainTitle: 'Candidates', subTitle: 'List of waiting candidates' }}
    >
      <Grid container direction="column" rowSpacing={2}>
        <Grid item width="100%">
          <CandidatesTable
            filters={filters}
            candidates={candidates}
            isLoadingCandidates={isLoadingCandidates}
            handleChangeFilters={handleChangeFilters}
            handleCloseFilter={handleCloseFilter}
          />
        </Grid>
        {isManyPages && (
          <Grid item container justifyContent="center">
            <Pagination
              count={pagesCount}
              shape="rounded"
              size="medium"
              showFirstButton
              showLastButton
              onChange={handleChangePage}
              page={candidatesPage}
              disabled={isLoadingCandidates}
            />
          </Grid>
        )}
      </Grid>
    </BDBContainer>
  );
};

export default Candidates;
