import { observer } from 'mobx-react';
import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { SignInRequest } from '../../types/api-identity.types';
import { FormErrors } from '../../components/FormErrors/form-errors';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';
import { SubInfo } from '../../layouts/Sub-Info/sub-info';
import userStore from '../../store/user.store';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { ContainerHeader } from '../../layouts/ContainerHeader/container-header';

const loginFormDefaultValues = {
  email: '',
  password: '',
};

const Login = () => {
  const [isProcessingSignIn, setIsProcessingSignIn] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<SignInRequest>({
    defaultValues: loginFormDefaultValues,
  });
  const { email: emailError, password: passwordError } = errors;

  const onSubmit: SubmitHandler<SignInRequest> = async (signInData: SignInRequest) => {
    setIsProcessingSignIn(true);
    const signInResult = await userStore.signIn(signInData);

    if (!signInResult) {
      setError('password', { message: 'Wrong password or email' });
    } else {
      navigate('/dashboard');
    }

    setIsProcessingSignIn(false);
  };

  return (
    <BDBContainer maxWidth="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          spacing={2}
          component="fieldset"
          disabled={isProcessingSignIn}
          sx={{ marginX: 'auto', width: 400, border: 'none' }}
        >
          <Grid item>
            <ContainerHeader
              mainTitle="Login"
              subTitle="Welcome back, please login to your account"
            />
          </Grid>
          <Grid item>
            <Controller
              name="email"
              control={control}
              rules={{ required: 'Email is required.' }}
              render={({ field: { onChange, value } }) => (
                <BDBTextField label="Email" onChange={onChange} value={value} fullWidth />
              )}
            />
            {emailError?.message && <FormErrors messages={[emailError.message]} />}
          </Grid>
          <Grid item>
            <Controller
              name="password"
              control={control}
              rules={{ required: 'Password is required.' }}
              render={({ field: { onChange, value } }) => (
                <BDBTextField
                  type="password"
                  label="Password"
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
            {passwordError?.message && <FormErrors messages={[passwordError?.message]} />}
          </Grid>
          <Grid item container justifyContent="center">
            <BDBButton type="submit" maxWidth="120px" loading={isProcessingSignIn}>
              Log In
            </BDBButton>
          </Grid>
          <Grid item>
            <SubInfo contents={['Contact your administrator to create new account.']} />
          </Grid>
        </Grid>
      </form>
    </BDBContainer>
  );
};

export default observer(Login);
