import { makeAutoObservable, runInAction } from 'mobx';
import { getBoards, GetBoardsResult, createBoard } from '../api/boards.api';

class BoardsStore {
  private _loaded = false;

  private _boards: GetBoardsResult = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchBoards = async () => {
    this.loaded = false;
    const fetchedBoards = await getBoards();
    runInAction(() => {
      this._boards = fetchedBoards;
    });
    this.loaded = true;
  };

  createBoard = async ({ name }: { name: string }) => {
    const createdBoard = await createBoard({ name });

    if (!createdBoard) return false;

    this._boards.unshift(createdBoard);

    return true;
  };

  set loaded(loaded: boolean) {
    this._loaded = loaded;
  }

  get loaded() {
    return this._loaded;
  }

  get boards() {
    return this._boards;
  }
}

export const boardsStore = new BoardsStore();
