import { AxiosResponse } from 'axios';
import { UserPostProps, UserPatchProps } from '../types/admin-panel.types';
import { authRequest } from '../services/api.services';
import { GetUsersResponse } from '../types/users-api.types';

interface GetUsersArgs {
  roles?: string[];
}

export const addUser = async (params: UserPostProps): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: '/users',
      method: 'post',
      data: params,
    });
    return response;
  } catch {
    return false;
  }
};

export const getUsers = async (args?: GetUsersArgs): Promise<GetUsersResponse> => {
  try {
    const { roles } = args || {};
    const response = await authRequest<{ users: GetUsersResponse }>({
      url: `/users`,
      method: 'get',
      params: {
        roles: roles?.join('&'),
      },
    });

    return response?.data?.users;
  } catch {
    return [];
  }
};

export const deleteUser = async (userId: string): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: `/users/${userId}`,
      method: 'delete',
    });
    return response;
  } catch {
    return false;
  }
};

export const editUser = async (
  userId: string,
  payload: UserPatchProps,
): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: `/users/${userId}`,
      method: 'patch',
      data: payload,
    });
    const res = response.data;
    return res;
  } catch {
    return false;
  }
};
