import { Link, ListItemButton, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { colorThemes } from '../../variables/style.variables';

interface IHeaderLinkPorps {
  label: string;
  link: string;
  icon: SvgIconComponent;
  onClick: () => void;
}

const HeaderLinkItem: FunctionComponent<IHeaderLinkPorps> = ({
  label,
  link,
  onClick,
  icon: Icon,
}) => {
  return (
    <ListItem disablePadding>
      <Link
        component={RouterLink}
        to={link}
        color={colorThemes.black}
        underline="none"
        width="100%"
      >
        <ListItemButton onClick={onClick}>
          <ListItemIcon>
            <Icon style={{ color: colorThemes.primary }} />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default HeaderLinkItem;
