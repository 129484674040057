import { useState, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { ModalTypes } from '../../variables/modal.variables';
import { getProjects } from '../../api/project.api';
import { BaseUseModalArgs } from '../../types/modal.types';
import { CLIENT_ORIGIN } from '../../variables/config';
import { IProject } from '../../types/project.types';

interface UseGenerateApplyUrlModalResult {
  openGenerateApplyUrlModal: () => void;
}

const ApplyProjects = ({ projects }: { projects: IProject[] }) => (
  <List>
    {projects.map((project) => (
      <ListItem key={project.id} dense>
        <ListItemText primary={project.name} secondary={`${CLIENT_ORIGIN}/apply/${project.id}`} />
      </ListItem>
    ))}
  </List>
);

export const useGenerateApplyUrlModal = ({
  modalStore,
}: BaseUseModalArgs): UseGenerateApplyUrlModalResult => {
  const [projects, setProjects] = useState<IProject[]>([]);

  const fetchProjects = async () => {
    const fetchedProjects = await getProjects();
    setProjects(fetchedProjects);
  };

  const openGenerateApplyUrlModal = () => {
    modalStore.push({
      title: "Project's apply urls",
      content: <ApplyProjects projects={projects} />,
      type: ModalTypes.Info,
    });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return { openGenerateApplyUrlModal };
};
