import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { addUser, editUser } from '../../api/users.api';
import { schema } from './add-edit-user.schema';
import { FormErrors } from '../../components/FormErrors/form-errors';
import { UserPostProps, UserGetProps, UserPatchProps } from '../../types/admin-panel.types';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';
import { Roles } from '../../types/user.types';
import { roleStore } from '../../store/role.store';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';

export const AddEditUser: FC<{
  user: UserGetProps;
  onClose: () => void;
  onSuccess: () => Promise<void>;
  openDialog: boolean;
}> = ({ user, onClose, openDialog, onSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { getRoleByName } = roleStore;
  const hrRole = getRoleByName(Roles.HR);
  const defaultValue = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    userId: user.userId,
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserGetProps>({
    resolver: yupResolver(schema),
  });
  const {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    phoneNumber: phoneNumberError,
  } = errors;
  const isUserExist = !!user.userId;

  const addUserOnSubmit = async (values: UserGetProps) => {
    if (!hrRole) return;

    setIsProcessing(true);
    const { firstName, lastName, email, phoneNumber } = values;
    const parsePhone = phoneNumber.replace(/-| /g, '');
    const postPayload: UserPostProps = {
      firstName,
      lastName,
      email,
      phoneNumber: parsePhone,
      roleId: hrRole.id,
    };
    const response = await addUser(postPayload);

    onSuccess();
    onClose();
    setIsProcessing(false);

    if (!response) {
      modalStore.push({
        title: 'Something went wrong',
        content: 'There was some error while creating new user',
        type: ModalTypes.Info,
      });
      return;
    }

    modalStore.push({
      title: 'User created',
      content: `User ${firstName} ${lastName} was created successfully.`,
      type: ModalTypes.Info,
    });
  };

  const editUserOnSubmit = async (values: UserGetProps) => {
    setIsProcessing(true);
    const { firstName, lastName, email, phoneNumber } = values;
    const patchPayload: UserPatchProps = {
      firstName: user.firstName === firstName ? null : firstName,
      lastName: user.lastName === lastName ? null : lastName,
      email: user.email === email ? null : email,
      phoneNumber: user.phoneNumber === phoneNumber ? null : phoneNumber,
    };
    const response = await editUser(user.userId, patchPayload);

    onSuccess();
    onClose();
    setIsProcessing(false);

    if (!response) {
      modalStore.push({
        title: 'Something went wrong',
        content: `There was some error while upadting ${firstName} ${lastName}.`,
        type: ModalTypes.Info,
      });
      return;
    }

    modalStore.push({
      title: 'User updated',
      content: `${firstName} ${lastName} was updated successfully.`,
      type: ModalTypes.Info,
    });
  };

  const onSubmit = async (values: UserGetProps) => {
    if (!isUserExist) {
      await addUserOnSubmit(values);
    } else {
      await editUserOnSubmit(values);
    }
  };

  const getDialogTitle = () => {
    return isUserExist ? `Edit ${user.firstName} ${user.lastName}` : 'Create new user';
  };

  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <DialogTitle>{getDialogTitle()}</DialogTitle>
      <DialogContent dividers>
        <form id="add-edit-user-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            component="fieldset"
            disabled={isProcessing}
            border="none"
            direction="column"
            rowSpacing={3}
            paddingY={2}
          >
            <Grid item container direction="row" columnSpacing={3}>
              <Grid item xs>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={defaultValue.firstName}
                  render={({ field: { onChange, onBlur } }) => (
                    <BDBTextField
                      label="First name"
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={defaultValue.firstName}
                      fullWidth
                    />
                  )}
                />
                {firstNameError?.message && <FormErrors messages={[firstNameError.message]} />}
              </Grid>
              <Grid item xs>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={defaultValue.lastName}
                  render={({ field: { onChange, onBlur } }) => (
                    <BDBTextField
                      label="Last name"
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultValue={defaultValue.lastName}
                      fullWidth
                    />
                  )}
                />
                {lastNameError?.message && <FormErrors messages={[lastNameError.message]} />}
              </Grid>
            </Grid>
            <Grid item>
              <Controller
                name="email"
                control={control}
                defaultValue={defaultValue.email}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField
                    label="Email"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={defaultValue.email}
                    fullWidth
                  />
                )}
              />
              {emailError?.message && <FormErrors messages={[emailError.message]} />}
            </Grid>
            <Grid item>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue={defaultValue.phoneNumber}
                render={({ field: { onChange, onBlur } }) => (
                  <BDBTextField
                    label="Phone number"
                    onChange={onChange}
                    onBlur={onBlur}
                    defaultValue={defaultValue.phoneNumber}
                    fullWidth
                  />
                )}
              />
              {phoneNumberError?.message && <FormErrors messages={[phoneNumberError.message]} />}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <BDBButton
              variant="text"
              type="submit"
              maxWidth="80px"
              form="add-edit-user-form"
              loading={isProcessing}
            >
              Save
            </BDBButton>
          </Grid>
          <Grid item>
            <BDBButton
              onClick={() => {
                onClose();
                reset((formValues) => ({
                  ...formValues,
                }));
              }}
              variant="text"
              maxWidth="100px"
              disabled={isProcessing}
            >
              Cancel
            </BDBButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default observer(AddEditUser);
