import axios from 'axios';
import { LocalStorageKeys } from '../variables/local-storage.variables';

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  },
);

export const authRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

authRequest.interceptors.request.use((req) => {
  const token = window.localStorage.getItem(LocalStorageKeys.AuthorizationToken);
  if (req && req.headers) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

authRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw error;
  },
);
