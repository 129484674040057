import { AxiosInstance, AxiosRequestConfig } from 'axios';

export const applyAuthorizationRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
};
