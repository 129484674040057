import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { BoardTaskItem } from '../../../../types/boards.types';
import { boardStore } from '../../../../store/board.store';
import { BacklogBoardTaskRow } from './components/backlog-board-task-row';

export const Backlog = observer(() => {
  const backlogBoardTasks = boardStore.backlogBoardTasksSortedByOrdinal || [];

  useEffect(() => {
    boardStore.fetchBacklogBoardTasks();
  }, [boardStore.board]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId: boardTaskId } = result;
    if (!destination || destination.index === source.index) return;

    await boardStore.updateBacklogBoardTaskOrdinal({
      boardTaskId,
      ordinal: destination.index,
    });
  };

  const renderedBoardTasks = () =>
    backlogBoardTasks.map((boardTask: BoardTaskItem) => (
      <BacklogBoardTaskRow key={boardTask.id} boardTask={boardTask} />
    ));

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="backlog-board-tasks-droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <TableBody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {renderedBoardTasks()}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
});
