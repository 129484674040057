import { ChangeEvent } from 'react';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { TextFieldProps } from '@mui/material';
import { IFormControl } from '../../types/form.types';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';
import { colorThemes } from '../../variables/style.variables';

interface DatePickerProps extends IFormControl {
  onType?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (value: Dayjs) => void;
}

export const DatePicker = ({
  views = ['year', 'month', 'day'],
  defaultValue = dayjs().format('YYYY-MM-DD'),
  control,
  label,
  registerOptions,
  id,
  error,
  disabled,
  onType,
  onChange = () => {},
}: DatePickerProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={id}
        defaultValue={defaultValue}
        control={control}
        rules={registerOptions}
        render={({ field: { onChange: controllerOnChange, ...restField } }) => (
          <MUIDatePicker
            disabled={disabled}
            views={views}
            label={registerOptions ? `${label} *` : `${label}`}
            onChange={(value) => {
              controllerOnChange(value);

              if (onChange) onChange(value);
            }}
            renderInput={(
              params: JSX.IntrinsicAttributes &
                (TextFieldProps & { customColor?: string | undefined }),
            ) => (
              <BDBTextField
                {...params}
                error={error}
                onChange={(e) => {
                  controllerOnChange(e);
                  if (onType) onType(e);
                }}
                fullWidth
              />
            )}
            {...restField}
            PaperProps={{
              sx: {
                '*.Mui-selected': {
                  backgroundColor: `${colorThemes.primary} !important`,
                  '&:hover': {
                    backgroundColor: colorThemes.primary,
                  },
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
