import { observer } from 'mobx-react';
import { Grid, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { boardStore } from '../../../../store/board.store';
import { BoardColumn } from './components/board-column';
import { BoardColumnCreate } from './components/board-column-create';
import {
  BOARD_COLUMN_WIDTH,
  BOARD_COLUMN_CREATE_WIDTH,
} from '../../../../variables/board-workspace.variables';
import { CustomScrollbar } from '../../../../components/CustomScrollbar/custom-scrollbar';
import { useCreateBoardColumnModal } from '../../../../hooks/modals/useCreateBoardColumnModal';
import { modalStore } from '../../../../store/modal.store';

const getBoardColumnTasksHeight = (): number => {
  const TAKEN_VIEW_HEIGHT = 370;
  const windowHeight = window.innerHeight;

  return windowHeight - TAKEN_VIEW_HEIGHT;
};

export const Board = observer(() => {
  const [boardColumnTasksHeight, setBoardColumnTasksHeight] = useState(0);
  const boardColumns = boardStore.boardColumnsSortedByOrdinal || [];
  const boardColumnsAmount = boardColumns?.length;
  const isNoBoardColumns = boardColumnsAmount === 0;
  const { onCreateBoardColumn } = useCreateBoardColumnModal({ modalStore });

  const onDragEnd = async (result: DropResult) => {
    const { source, destination, draggableId: boardColumnId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    await boardStore.updateBoardColumnOrdinal({
      boardColumnId,
      ordinal: destination.index,
    });
  };

  const handleChangeBoardColumnTasksHeight = () => {
    setBoardColumnTasksHeight(getBoardColumnTasksHeight());
  };

  useEffect(() => {
    setBoardColumnTasksHeight(getBoardColumnTasksHeight());

    window.addEventListener('resize', handleChangeBoardColumnTasksHeight);

    return () => {
      window.removeEventListener('resize', handleChangeBoardColumnTasksHeight);
    };
  }, []);

  useEffect(() => {
    if (boardStore.loaded && isNoBoardColumns) {
      onCreateBoardColumn({ isFirstBoardColumn: isNoBoardColumns });
    }
  }, [boardStore.loaded, isNoBoardColumns]);

  const renderedBoardColumns = boardColumns.map((boardColumn) => (
    <BoardColumn
      key={boardColumn.id}
      boardColumn={boardColumn}
      boardColumnTasksHeight={boardColumnTasksHeight}
    />
  ));

  if (!boardColumns?.length) return null;

  return (
    <CustomScrollbar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box style={{ overflowX: 'auto' }} width="100%" display="flex" flexDirection="row">
          <Droppable droppableId="board-columns-droppable" direction="horizontal">
            {(provided) => (
              <Grid
                container
                direction="row"
                flexGrow="no wrap"
                columnSpacing={2}
                my={4}
                minWidth={boardColumnsAmount * BOARD_COLUMN_WIDTH + BOARD_COLUMN_CREATE_WIDTH}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {renderedBoardColumns}
                {provided.placeholder}
                <BoardColumnCreate onClick={() => onCreateBoardColumn({})} />
              </Grid>
            )}
          </Droppable>
        </Box>
      </DragDropContext>
    </CustomScrollbar>
  );
});
