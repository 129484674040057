import { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, Paper, TableRow, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import Skeleton from '@mui/material/Skeleton';
import { observer } from 'mobx-react';
import { AdminUsersTableHead } from './components/admin-users-table-head';
import { getUsers, deleteUser } from '../../api/users.api';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { AddEditUser } from '../../forms/User/add-edit-user';
import { UserGetProps } from '../../types/admin-panel.types';
import { roleStore } from '../../store/role.store';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';

const initialValueRecruiter = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  userId: '',
  appUserId: '',
};

const usersLoadingSkeleton = (
  <>
    {[1, 2, 3, 4, 5].map(() => (
      <TableRow key={Math.random()}>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" height={20} />
        </TableCell>
      </TableRow>
    ))}
  </>
);

function AdminUsers() {
  const [recruiters, setRecrutiers] = useState<UserGetProps[]>([]);
  const [editRecruiter, setEditRecruiter] = useState<UserGetProps>(initialValueRecruiter);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);

    const users = await getUsers();

    setRecrutiers(users);
    setLoading(false);
  };

  const onDeleteUser = async (user: UserGetProps) => {
    const { firstName, lastName, userId } = user;

    modalStore.push({
      title: 'Delete confirmation',
      content: `Are you sure you want to delete ${firstName} ${lastName}?`,
      type: ModalTypes.Confirmation,
      onAgree: async (currentModal) => {
        modalStore.update({ ...currentModal, isLoading: true });

        const response = await deleteUser(userId);
        await fetchUsers();

        if (response) {
          modalStore.push({
            title: 'User deleted',
            content: `${firstName} ${lastName} was deleted`,
            type: ModalTypes.Info,
          });
        } else {
          modalStore.push({
            title: 'Something went wrong',
            content: 'There was error while removing user.',
            type: ModalTypes.Info,
          });
        }
      },
    });
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const onAddRecruiter = () => {
    setEditRecruiter(initialValueRecruiter);
    setOpenDialog(true);
  };
  const onEdit = (user: UserGetProps) => {
    setEditRecruiter(user);
    setOpenDialog(true);
  };

  useEffect(() => {
    roleStore.fetchRoles();
    fetchUsers();
  }, []);

  return (
    <BDBContainer
      maxWidth="xl"
      header={{
        mainTitle: 'Users',
        subTitle: 'List of users on the application',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        children: (
          <BDBButton variant="text" fontWeight={400} onClick={onAddRecruiter}>
            <AddCircleOutlinedIcon sx={{ marginRight: '4px' }} />
            Create
          </BDBButton>
        ),
      }}
    >
      <Paper>
        <TableContainer>
          <Table>
            <AdminUsersTableHead />
            <TableBody>
              {loading
                ? usersLoadingSkeleton
                : recruiters.map((user) => {
                    return (
                      <TableRow key={user.email}>
                        <TableCell>
                          {user.firstName} {user.lastName}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.phoneNumber}</TableCell>
                        <TableCell>
                          <Grid container direction="row">
                            <Grid item>
                              <BDBButton
                                variant="text"
                                fontWeight={400}
                                onClick={() => onEdit(user)}
                                fontSize="14px"
                              >
                                <EditIcon sx={{ marginRight: '4px' }} />
                                Edit
                              </BDBButton>
                            </Grid>
                            <Grid item>
                              <BDBButton
                                variant="text"
                                fontWeight={400}
                                onClick={() => onDeleteUser(user)}
                                fontSize="14px"
                              >
                                <DeleteIcon sx={{ marginRight: '4px' }} />
                                Delete
                              </BDBButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <AddEditUser
          user={editRecruiter}
          openDialog={openDialog}
          onClose={onCloseDialog}
          onSuccess={fetchUsers}
        />
      </Paper>
    </BDBContainer>
  );
}

export default observer(AdminUsers);
