import { authRequest } from '../services/api.services';
import { GetFileProps, ResponsePostFileProps } from '../types/file.types';

export const addFile = async (
  id: string,
  payload: FormData,
): Promise<ResponsePostFileProps | false> => {
  try {
    const response = await authRequest({
      url: `files/person/${id}`,
      method: 'post',
      data: payload,
      headers: {
        'Content-Type': undefined,
      },
    });
    return response?.data;
  } catch {
    return false;
  }
};

export const getFileType = async (): Promise<GetFileProps[]> => {
  try {
    const response = await authRequest({
      url: `/file-types`,
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};
