import { PropsWithChildren, isValidElement, cloneElement, ReactElement } from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';
import { Modal } from '../../types/modal.types';

enum DefaultModalContents {
  Agree = 'Agree',
  Close = 'Close',
}

interface ModalProviderProps extends PropsWithChildren {}

export interface ModalContentElementProps {
  closeModal?: () => void;
}

export const ModalProvider = observer(({ children }: ModalProviderProps) => {
  const { modals } = modalStore;

  const getDialogContent = (modal: Modal) => {
    const { content } = modal;
    const isContentElement = isValidElement(content);

    const contentValue = isContentElement ? (
      cloneElement(content as ReactElement<ModalContentElementProps>, {
        closeModal: () => modalStore.close({ id: modal.id }),
      })
    ) : (
      <DialogContentText fontSize="14px" lineHeight="20px">
        {content}
      </DialogContentText>
    );

    const dialogContent =
      modal.type === ModalTypes.CustomContent ? (
        contentValue
      ) : (
        <DialogContent dividers>{contentValue}</DialogContent>
      );

    return dialogContent;
  };

  const getDialogActions = (modal: Modal) => {
    if (modal.type === ModalTypes.CustomContent) return null;

    return (
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end">
          {modal.type === ModalTypes.Confirmation && (
            <Grid item>
              <BDBButton
                {...modal.agreeProps}
                variant="text"
                onClick={() => modalStore.close({ id: modal.id, callback: modal.onAgree })}
                loading={modal.isLoading}
              >
                {modal.agreeContent ?? DefaultModalContents.Agree}
              </BDBButton>
            </Grid>
          )}
          <Grid item>
            <BDBButton
              {...modal.closeProps}
              variant="text"
              onClick={() => modalStore.close({ id: modal.id, callback: modal.onClose })}
              disabled={modal.isLoading}
            >
              {modal.closeContent ?? DefaultModalContents.Close}
            </BDBButton>
          </Grid>
        </Grid>
      </DialogActions>
    );
  };

  const renderedModals = modals.map((modal) => {
    const dialogContent = getDialogContent(modal);
    const dialogActions = getDialogActions(modal);

    return (
      <Dialog
        open={modal.isOpen}
        key={modal.id}
        maxWidth={modal.maxWidth}
        TransitionComponent={Grow}
        fullWidth
      >
        <DialogTitle>
          {modal.title}
          <IconButton
            aria-label="close"
            onClick={() => modalStore.close({ id: modal.id, callback: modal.onClose })}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            disabled={modal.isLoading}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        {dialogContent}
        {dialogActions}
      </Dialog>
    );
  });

  return (
    <>
      {children}
      {renderedModals}
    </>
  );
});
