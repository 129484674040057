import { FunctionComponent, ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import { BDBTextField } from '../../layouts/BDB-TextField/bdb-text-field';
import { IFormControl } from '../../types/form.types';

interface ITextareaProps extends IFormControl {
  rows: number;
  onType?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const TextArea: FunctionComponent<ITextareaProps> = ({
  label,
  defaultValue = '',
  id,
  control,
  registerOptions,
  error,
  rows,
  onType,
}) => {
  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={registerOptions}
      render={({ field: { onChange, onBlur } }) => (
        <BDBTextField
          id={id}
          label={label}
          fullWidth
          onChange={(e) => {
            onChange(e);
            if (onType) onType(e);
          }}
          onBlur={onBlur}
          error={error}
          defaultValue={defaultValue}
          multiline
          rows={rows}
        />
      )}
    />
  );
};

export default TextArea;
