import { AxiosResponse } from 'axios';
import { authRequest, request } from '../services/api.services';
import {
  ICandidateParams,
  IPersonDetailed,
  IPositionResponse,
  ISeniority,
} from '../types/candidate.types';
import { IUser, Roles } from '../types/user.types';

export const getPositions = async (): Promise<IPositionResponse[]> => {
  try {
    const response = await request({
      url: '/job-positions',
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};

export const getSeniorities = async (): Promise<ISeniority[]> => {
  try {
    const response = await request({
      url: '/seniorities',
      method: 'get',
    });
    return response?.data;
  } catch {
    return [];
  }
};

export const getRecruiterList = async (role: Roles): Promise<IUser[]> => {
  try {
    const response = await authRequest({
      url: `/users?${role}`,
      method: 'get',
    });
    return response?.data?.users;
  } catch {
    return [];
  }
};
export const addCandidate = async (params: ICandidateParams): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: '/persons/candidate',
      method: 'post',
      data: params,
    });
    return response;
  } catch {
    return false;
  }
};

export const editCandidate = async (params: {
  id: string;
  data: ICandidateParams;
}): Promise<AxiosResponse | false> => {
  try {
    const response = await authRequest({
      url: `/persons/${params.id}`,
      method: 'patch',
      data: params.data,
    });
    return response;
  } catch {
    return false;
  }
};

export const getCandidateById = (id: string): Promise<AxiosResponse<IPersonDetailed>> => {
  return authRequest({
    url: `/persons/${id}`,
    method: 'get',
  });
};
