import { useState } from 'react';
import { observer } from 'mobx-react';
import { Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { schema } from './add-employee.schema';
import { BDBButton } from '../../layouts/BDB-Button/bdb-button';
import { BDBContainer } from '../../layouts/BDB-Container/bdb-container';
import { Onboarding } from './Onboarding/onboarding';
import { Agreements } from './Agreements/agreements';
import { AdditionalInformation } from './AdditionalInformation/additional-information';
import { EmployeeProps, PostEmployeeProps } from '../../types/add-employee.types';
import { Equipment } from './Equipment/equipment';
import { Benefits } from './Benefits/benefits';
import { defaultValues } from './add-employee.defaultValue';
import { addEmployee } from '../../api/employee.api';
import { modalStore } from '../../store/modal.store';
import { ModalTypes } from '../../variables/modal.variables';
import { addFile } from '../../api/file.api';

const AddEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [equipmentFile, setEquipmentFile] = useState<FormData | null>();
  const [certificateOfEmploymentFile, setCertificateOfEmploymentFile] = useState<FormData | null>();
  const [ppkFile, setPpkFile] = useState<FormData | null>();
  const [pit2File, setPit2File] = useState<FormData | null>();
  const [hobbyFile, setHobbyFile] = useState<FormData | null>();
  const methods = useForm<EmployeeProps>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = async (values: EmployeeProps) => {
    setIsProcessing(true);
    const {
      medicalChecksExpiryDate,
      medicalChecks,
      equipment,
      emailCreation,
      calamariAccess,
      primetric,
      officeCard,
      firstAid,
      bhp,
      expiryDate,
      type,
      phone,
      stage,
      contractExtended,
      nda,
      multiSport,
      luxMed,
      startDate,
      welcomePack,
      lastContact,
      onboarding,
    } = values;
    const payload: PostEmployeeProps = {
      startDate: new Date(startDate),
      welcomePack,
      lastContact: new Date(lastContact),
      onboarding: new Date(onboarding),
      equipment,
      phone,
      emailCreation,
      calamariAccess,
      primetric,
      officeCard,
      firstAid,
      bhp,
      agreement: {
        expiryDate: new Date(expiryDate),
        type,
        stage,
        contractExtended,
        nda,
      },
      documents: {
        medicalChecksExpiryDate: new Date(medicalChecksExpiryDate),
        medicalChecks,
      },
      benefits: {
        multiSport,
        luxMed,
      },
    };

    if (equipmentFile && id) {
      await addFile(id, equipmentFile);
    }
    if (certificateOfEmploymentFile && id) {
      await addFile(id, certificateOfEmploymentFile);
    }
    if (ppkFile && id) {
      await addFile(id, ppkFile);
    }
    if (pit2File && id) {
      await addFile(id, pit2File);
    }
    if (hobbyFile && id) {
      await addFile(id, hobbyFile);
    }

    setIsProcessing(false);

    const response = id && (await addEmployee(id, payload));
    if (response) {
      modalStore.push({
        title: 'Employee Created',
        content: `Employee ${response.firstName} ${response.lastName} was created successfully.`,
        type: ModalTypes.Info,
      });
      navigate('/candidates');
    } else {
      modalStore.push({
        title: 'Something went wrong',
        content: 'There was error while creating employee.',
        type: ModalTypes.Info,
      });
    }
  };

  return (
    <BDBContainer
      maxWidth="md"
      header={{
        mainTitle: 'Create employee',
        subTitle: 'Fill form out to create new employee',
      }}
    >
      <FormProvider {...methods}>
        <form id="add-edit-user-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid
            container
            component="fieldset"
            disabled={isProcessing}
            border="none"
            rowSpacing={3}
            paddingY={2}
          >
            <Onboarding />
            <Equipment {...{ setEquipmentFile }} />
            <Agreements />
            <AdditionalInformation
              {...{ setCertificateOfEmploymentFile, setPit2File, setPpkFile }}
            />
            <Benefits {...{ setHobbyFile }} />
            <Grid item container justifyContent="center">
              <BDBButton
                form="add-edit-user-form"
                loading={isProcessing}
                maxWidth="150px"
                type="submit"
              >
                Sumbit
              </BDBButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </BDBContainer>
  );
};

export default observer(AddEmployee);
