import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LoginIcon from '@mui/icons-material/Login';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Drawer, List } from '@mui/material';
import { observer } from 'mobx-react';
import userStore from '../../store/user.store';
import HeaderLinkItem from './link-item';

interface HeaderAppNavProps {
  toggleAppNav: () => void;
  isOpenAppNav: boolean;
}

export const HeaderAppNav = observer(({ toggleAppNav, isOpenAppNav }: HeaderAppNavProps) => {
  const userRolesInfo = userStore.rolesInfo;

  return (
    <Drawer
      anchor="left"
      open={isOpenAppNav}
      onClose={toggleAppNav}
      PaperProps={{
        sx: {
          width: '250px',
        },
      }}
    >
      <List>
        {userRolesInfo.isGuest && (
          <HeaderLinkItem icon={LoginIcon} link="/login" label="Login" onClick={toggleAppNav} />
        )}
        {(userRolesInfo.isHR || userRolesInfo.isAdmin) && (
          <>
            <HeaderLinkItem
              icon={DashboardOutlinedIcon}
              link="/dashboard"
              label="Dashboard"
              onClick={toggleAppNav}
            />
            <HeaderLinkItem
              icon={ListAltIcon}
              link="/employees"
              label="Employees"
              onClick={toggleAppNav}
            />
            <HeaderLinkItem
              icon={ListAltIcon}
              link="/candidates"
              label="Candidates"
              onClick={toggleAppNav}
            />
            <HeaderLinkItem
              icon={ListAltIcon}
              link="/applications"
              label="Applications"
              onClick={toggleAppNav}
            />
            <HeaderLinkItem
              icon={ListAltIcon}
              link="/projects"
              label="Projects"
              onClick={toggleAppNav}
            />
            <HeaderLinkItem
              icon={PostAddIcon}
              link="/candidates/create"
              label="New candidate"
              onClick={toggleAppNav}
            />
          </>
        )}
        {userRolesInfo.isAdmin && (
          <HeaderLinkItem
            icon={AdminPanelSettingsOutlinedIcon}
            link="/admin/users"
            label="Admin"
            onClick={toggleAppNav}
          />
        )}
      </List>
    </Drawer>
  );
});
