import axios from 'axios';
import { applyAuthorizationRequestInterceptor } from './interceptors';
import { API_URL } from '../variables/config';

export const axiosAuthorized = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
});

applyAuthorizationRequestInterceptor(axiosAuthorized);
