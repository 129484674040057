import Container, { ContainerProps } from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { ContainerHeader, ContainerHeaderProps } from '../ContainerHeader/container-header';

interface BDBContainerProps extends ContainerProps {
  header?: ContainerHeaderProps;
}

export const BDBContainer = ({ children, header, ...props }: BDBContainerProps) => {
  return (
    <Container {...props}>
      <Grid my={3}>
        {header && <ContainerHeader {...header} />}
        {children}
      </Grid>
    </Container>
  );
};
